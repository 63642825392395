@import "../../styles/foundations/mixins.scss";

.landing_screen_container_full {
  position: relative;
  width: 100% !important;

  .landing_screen_container {
    // position: relative;
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    scroll-snap-type: y mandatory;
    scroll-behavior: smooth;
    scroll-snap-stop: always;

    @include wrapper1 {
      scroll-snap-type: none;
    }

    .section1 {
      scroll-snap-align: start;
      scroll-behavior: smooth;
    }

    .section2 {
      scroll-snap-align: start;
      scroll-behavior: smooth;
    }

    .section3 {
      scroll-snap-align: start;
      scroll-behavior: smooth;
    }

    .section4 {
      scroll-snap-align: start;
      scroll-behavior: smooth;
    }

    .section5 {
      scroll-snap-align: start;
      scroll-behavior: smooth;

      padding-top: 50px;

      @include wrapper1 {
        padding-top: 0;
      }
    }

    .section6 {
      scroll-snap-align: start;
      scroll-behavior: smooth;
      background-color: #ffbf0f;

      .s_cont {
        height: 100vh;
        width: 100%;

        @include wrapper1 {
          height: 100%;
        }
      }
    }

    .section7 {
      scroll-snap-align: start;
      scroll-behavior: smooth;
    }

    .section8 {
      scroll-snap-align: start;
      scroll-behavior: smooth;
    }

    .section9 {
      scroll-snap-align: start;
      scroll-behavior: smooth;
    }

    .sectionFooter {
      scroll-snap-align: start;
      scroll-behavior: smooth;
    }
  }

  .right_navigation {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    z-index: 100;

    @include wrapper1 {
      display: none;
    }

    .right_navigation_container {
      display: flex;
      flex-direction: column;
      gap: 5px;
      margin-right: 20px;

      .yollowLeft {
        border-right: 2px solid #ffbf0f !important;
      }

      .right_navigation_container_holder {
        display: flex;
        justify-content: space-between;
        border-right: 1px solid #2b2b2b;

        .right_navigation_item {
          opacity: 1;
          background-color: aliceblue;
          padding: 5px;
          font-size: 12px;
          cursor: pointer;
          width: 100%;
          box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

          //on hover change the opacity to 1
          &:hover {
            opacity: 1 !important;
            //replay the animation
            // animation: none !important;

            transition: opacity 0.5s ease-in-out;
          }

          //using keyframe after 5 second to change the opacity
          animation: fadeOut 5s forwards;
          @keyframes fadeOut {
            0% {
              opacity: 1;
            }
            20% {
              opacity: 1;
            }
            40% {
              opacity: 1;
            }
            60% {
              opacity: 1;
            }
            80% {
              opacity: 1;
            }
            100% {
              opacity: 0;
            }
          }
        }

        .active {
          opacity: 1;
          animation: none !important;
        }

        .inactive {
          opacity: 0;
          animation: none !important;
        }
      }
    }
  }

  .endTextGradient {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 58px;

    // background: linear-gradient(101deg, #010101 2.08%, #febe13 58.14%);
    background: var(--black__yellow__text__shadow);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .nNadEventTextGradient {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 40px;

    background: var(--white__yellow__text__shadow);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

//hide scrollbar
/* Hide scrollbar for Chrome, Safari and Opera */
.landing_screen_container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */

.landing_screen_container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
