@import "../../../../styles/foundations/mixins.scss";

.primaryBtnWithBorder {
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;

  gap: 10px;
  padding: 10px 20px;
  border: 1px solid var(--border__black);
  transition: all 0.3s ease-in-out;

  &:hover {
    cursor: pointer;
    border: 1px solid var(--border__white);
    transition: all 0.3s ease-in-out;

    svg {
      #Vector_1 {
        // fill: orange;
        fill: white;
        transition: all 0.1s ease-in-out;
      }
      #Vector_2 {
        // fill: orange;
        fill: white;
        transition: all 0.2s ease-in-out;
      }
      #Vector_4 {
        // fill: orange;
        fill: white;
        transition: all 0.4s ease-in-out;
      }
      #Vector_3 {
        // fill: orange;
        fill: white;
        transition: all 0.5s ease-in-out;
      }
      #Vector_5 {
        // fill: orange;
        fill: white;
        transition: all 0.7s ease-in-out;
      }
    }
  }

  @include extraLargeTablet {
    padding: 8px 15px;
  }

  .primaryBtnWithBorder__text {
    font-size: 20px;
    font-weight: 600;

    @include extraLargeTablet {
      font-size: 16px;
    }

    @include mediumTablet {
      font-size: 14px;
    }
  }

  svg {
    width: 56px;
    height: 24px;

    @include extraLargeTablet {
      width: 20px;
      height: 20px;
    }

    @include mediumTablet {
      width: 16px;
      height: 16px;
    }
  }
}
