@import "../../../styles/foundations/mixins.scss";

.landing__targetMarket {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  gap: 30px;
  padding-top: 110px;

  @include wrapper1 {
    height: 100%;
    padding: 30px 0;
  }

  @include miniPortraitTablet {
    gap: 20px;
  }

  .landing__targetMarketContainer {
    display: flex;
    flex-direction: column;
    gap: 30px;

    .targetMarketTitleAndIcon {
      width: max-content;
      display: flex;
      flex-direction: column;

      .targetMarkett__title {
        font-size: 56px;
        font-style: normal;
        font-weight: 700;

        @include wrapper1 {
          font-size: 36px;
        }

        @include tablet {
          font-size: 26px;
        }

        @include largeMobile {
          font-size: 20px;
        }
      }

      img {
        width: 100%;
        height: 5px;
      }
    }

    .targetMarkett__desc {
      font-size: 18px;
      margin-top: 15px;

      @include miniPortraitTablet {
        font-size: 16px;
      }

      @include largeMobile {
        font-size: 15px;
      }
    }

    .targetMarket__container21 {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
      gap: 25px;

      @include largeTablet {
        grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
      }

      @include largeMobile {
        grid-template-columns: 1fr;
      }

      .targerMartet__content {
        width: 100%;
        height: 210px;
        display: flex;
        cursor: pointer;

        .targerMartet__contentImage {
          position: relative;
          height: 100%;
          width: 100%;
          display: flex;
          background-color: black;

          img {
            height: 100%;
            width: 100%;
            object-fit: fill;
            opacity: 65%;
          }

          .targerMartet__contentTitle21 {
            position: absolute;
            bottom: 0;
            left: 0;

            padding: 10px;

            span {
              width: 150px;
              height: 100%;
              font-size: 24px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px;
              color: white;

              @include largeMobile {
                font-size: 20px;
              }
            }
          }
        }
      }
    }

    .targetMarket__btn {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }
}
