@import "../../styles/foundations/mixins.scss";

.aboutPage {
  width: 100%;
  display: flex;
  flex-direction: column;

  .aboutContainerTop {
    display: flex;
    flex-direction: column;
    gap: 50px;

    padding: 64px 0 0 0;
    box-sizing: border-box;

    @include tablet {
      padding: 30px 0 40px 0;
      transition: all 0.5s ease-in-out;
    }

    @include largeMobile {
      padding: 20px 0 40px 0;
    }

    .aputPage__about {
      display: flex;
      flex-direction: column;

      gap: 30px;

      @include tablet {
        gap: 20px;
        transition: all 0.5s ease-in-out;
      }

      // @include largeMobile {
      //   gap: 10px;
      // }

      h2 {
        font-size: 48px;
        font-weight: 700;
        line-height: 58px;

        @include tablet {
          font-size: 36px;
          line-height: 46px;
          transition: all 0.5s ease-in-out;
        }

        @include largeMobile {
          font-size: 22px;
          line-height: 24px;
          transition: all 0.5s ease-in-out;
        }
      }
    }

    .aboutVideoContainer {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      padding: 4.5rem 0 4.5rem 0;
      box-sizing: border-box;

      @include tablet {
        padding: 2rem 0 1rem 0;
      }

      .aboutVideo {
        width: 750px;
        height: 400px;

        @include tablet {
          width: 100%;
          height: auto;
          transition: all 0.5s ease-in-out;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }

  .aboutPage__history {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: black;

    margin-bottom: 4.5rem;

    .historyContent__container {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;

      gap: 20px;
      padding: 110px 0;
      box-sizing: border-box;

      @include extraLargeTablet {
        padding: 80px 0;
      }

      @include tablet {
        padding: 40px 0;
      }

      @include miniPortraitTablet {
        padding: 0 0;
        gap: 0px;
      }

      .historyContent__HeaderAndContent {
        display: flex;
        flex-direction: column;

        gap: 20px;

        @include miniPortraitTablet {
          gap: 0px;
        }

        .historyHeader {
          display: flex;
          align-items: flex-start;
          flex-direction: column;
          padding: 20px 0 20px 0;

          .historyHeader__text {
            font-size: 35px;
            font-weight: 700;
            color: white;

            @include tablet {
              font-size: 25px;
              transition: all 0.5s ease-in-out;
            }

            @include largeMobile {
              font-size: 18px;
              transition: all 0.5s ease-in-out;
            }
          }

          img {
            height: 5px;
            width: max-content;

            @include tablet {
              height: 3px;
              transition: all 0.5s ease-in-out;
            }
          }
        }

        .aboutPage__historyContent {
          width: 100%;
          height: 400px;

          display: grid;
          grid-template-columns: 1fr 1fr;
          align-items: center;

          gap: 50px;
          margin-bottom: 35px;
          overflow: hidden;

          @include wrapper1 {
            height: 100%;
            grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
          }

          @include largeMobile {
            gap: 10px;
            // grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
            grid-template-columns: 1fr;
          }

          .historyContent__left {
            width: 100%;
            height: 100%;
            display: flex;

            .historyContent_ImageSection {
              height: 100%;
              width: 100%;
              display: flex;

              // @include largeMobile {
              //   height: 200px;
              //   width: 100%;
              // }

              img {
                width: 100%;
                height: 100%;
                object-fit: fill;
              }
            }
          }

          .historyContent__right {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;

            gap: 10px;
            transition: all 0.5s ease-in-out;

            .historyDate {
              font-weight: 400;
              font-size: 28px;
              line-height: 48px;
              color: white;

              @include tablet {
                font-size: 22px;
                line-height: 24px;
                transition: all 0.5s ease-in-out;
              }

              @include largeMobile {
                font-size: 15px;
                line-height: 20px;
                transition: all 0.5s ease-in-out;
              }
            }

            .categoryName {
              font-weight: 700;
              font-size: 32px;
              line-height: 40px;
              color: white;

              padding-bottom: 5px;
              border-bottom: 2px solid gray;
              margin-bottom: 20px;

              @include tablet {
                font-size: 22px;
                line-height: 24px;
                transition: all 0.5s ease-in-out;
              }

              @include largeMobile {
                font-size: 16px;
                line-height: 20px;
                transition: all 0.5s ease-in-out;
              }
            }

            .historyParagraph {
              color: white;
              text-align: justify;

              @include tablet {
                font-size: 15px;
                line-height: 20px;
                transition: all 0.5s ease-in-out;
              }

              @include largeMobile {
                font-size: 14px;
                line-height: 16px;
                transition: all 0.5s ease-in-out;
              }
            }
          }
        }
      }

      .historyEvent {
        width: 100%;
        height: 150px;
        display: flex;
        align-items: center;
        justify-content: center;

        @include extraLargeTablet {
          overflow-x: scroll;
        }

        @include miniPortraitTablet {
          justify-content: flex-start;
          height: 130px;
        }

        .barContainer {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-top: 20px;

          .icon-h1 {
            font-size: 25px;
            color: white;

            @include extraLargeTablet {
              font-size: 20px;
            }

            @include tablet {
              font-size: 15px;
            }
          }

          .barLine {
            height: 3px;
            width: 80px;
            background-color: white;

            @include extraLargeTablet {
              height: 2px;
              width: 60px;
            }

            @include tablet {
              height: 2px;
              width: 40px;
            }

            @include miniPortraitTablet {
              height: 2px;
              width: 30px;
            }
          }

          .barBoxContainer {
            position: relative;
            display: flex;
            flex-direction: column;

            .month {
              position: absolute;
              top: -35px;
              font-size: 16px;
              text-align: center;
              color: white;
              transition: all 0.3s ease-in-out;

              @include tablet {
                font-size: 13px;
              }

              @include miniPortraitTablet {
                font-size: 11px;
                top: -25px;
              }
            }

            .barBox {
              height: 16px;
              width: 16px;
              border: 3px solid white;
              cursor: pointer;

              @include extraLargeTablet {
                height: 12px;
                width: 12px;
              }

              @include tablet {
                height: 10px;
                width: 10px;
              }
            }

            .year {
              position: absolute;
              top: 25px;
              font-size: 16px;

              color: white;
              text-align: center;
              transition: all 0.3s ease-in-out;

              @include tablet {
                font-size: 13px;
              }

              @include miniPortraitTablet {
                font-size: 11px;
                top: 18px;
              }
            }

            .active {
              background-color: orange;
              transform: scale(1.3);
              transition: all 0.3s ease-in-out;
            }

            .activeText {
              transform: scale(1.2);
              transition: all 0.3s ease-in-out;
              color: orange !important;

              .month {
                transform: rotate(-90deg);
              }
              .year {
                transform: rotate(-90deg);
              }
            }
          }
        }
      }
    }
  }

  .aboutPage__teamAndclient {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 0 0 100px 0;
    box-sizing: border-box;

    @include tablet {
      padding: 0 0 50px 0;
      transition: all 0.5s ease-in-out;
    }

    @include largeMobile {
      padding: 0 0 40px 0;
    }

    .aboutPage__team {
      width: 100%;
      display: flex;
      flex-direction: column;

      gap: 40px;

      @include tablet {
        gap: 20px;
        transition: all 0.5s ease-in-out;
      }

      .aboutCompHeader {
        span {
          font-size: 48px;
          font-weight: 700;
          transition: all 0.5s ease-in-out;

          @include tablet {
            font-size: 36px;
            transition: all 0.5s ease-in-out;
          }

          @include largeMobile {
            font-size: 22px;
            transition: all 0.5s ease-in-out;
          }
        }
      }

      .aboutPage__teamSwiperContainer {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;

        gap: 30px;

        .aboutPage__teamSwiper {
          width: 100%;
          height: 100%;

          .aboutPage__teamSwiperSlide {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            .teamSwiperSlide__image {
              width: 100%;
              height: 220px;
              display: flex;
              background-color: #c4bbbb;

              @include tablet {
                height: 200px;
                transition: all 0.5s ease-in-out;
              }

              @include largeMobile {
                height: 180px;
                transition: all 0.5s ease-in-out;
              }

              img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }

            .teamSwiperSlide__content {
              width: 100%;
              height: 220px;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: space-between;

              margin-top: 15px;
              padding: 20px;
              box-sizing: border-box;
              background-color: #747474;

              @include tablet {
                height: 200px;
                transition: all 0.5s ease-in-out;
              }

              @include largeMobile {
                height: 200px;
                transition: all 0.5s ease-in-out;
              }

              .teamSwiperSlide__contentText {
                display: flex;
                flex-direction: column;

                .teamSwiperSlide__contentTextName {
                  font-size: 25px;
                  font-weight: 700;
                  color: white;

                  @include tablet {
                    font-size: 20px;
                    transition: all 0.5s ease-in-out;
                  }

                  @include largeMobile {
                    font-size: 18px;
                    transition: all 0.5s ease-in-out;
                  }
                }

                .teamSwiperSlide__contentTextPos {
                  font-size: 18px;
                  font-weight: 400;
                  color: white;

                  @include tablet {
                    font-size: 15px;
                    transition: all 0.5s ease-in-out;
                  }
                }
              }

              .teamSwiperSlide__contentIcon {
                display: flex;
                gap: 10px;

                i {
                  font-size: 18px;
                  color: white;
                  padding: 5px;
                  border: 1px solid white;
                  border-radius: 5px;

                  @include tablet {
                    font-size: 15px;
                    transition: all 0.5s ease-in-out;
                  }

                  @include largeMobile {
                    font-size: 12px;
                    transition: all 0.5s ease-in-out;
                  }
                }
              }
            }
          }
        }

        .aboutPage__swiperBtnCont {
          display: flex;
          gap: 15px;

          .aboutPage__swiperBtn {
            height: 40px;
            width: 40px;
            cursor: pointer;
            transition: all 0.5s ease-in-out;

            @include tablet {
              height: 30px;
              width: 30px;
              transition: all 0.5s ease-in-out;
            }
          }

          .deactiveBtn {
            opacity: 0.5;
            cursor: not-allowed;
          }

          .aboutPage__swiperBtn:nth-child(1) {
            transform: rotate(180deg);
          }
        }
      }
    }

    .aboutPage__Clients {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      padding: 100px 0 0 0;
      box-sizing: border-box;

      @include tablet {
        padding: 50px 0 0 0;
        transition: all 0.5s ease-in-out;
      }

      @include largeMobile {
        padding: 30px 0 0 0;
      }

      .aboutPage__clientsContainer {
        width: 100%;
        max-width: 1000px;
        display: flex;
        flex-direction: column;

        gap: 40px;

        @include tablet {
          gap: 20px;
          transition: all 0.5s ease-in-out;
        }

        .aboutPage__clientsHeaderNadIcon {
          width: max-content;
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          span {
            font-size: 48px;
            font-weight: 700;
            color: #2b2b2b;

            @include tablet {
              font-size: 36px;
              transition: all 0.5s ease-in-out;
            }

            @include largeMobile {
              font-size: 22px;
              transition: all 0.5s ease-in-out;
            }
          }

          img {
            width: 80%;
            height: 6px;
            object-fit: contain;
          }
        }

        .aboutPage__clientsList {
          width: 100%;
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));

          gap: 20px;

          @include tablet {
            gap: 10px;
            grid-template-columns: 1fr 1fr;
          }

          .aboutPage__clientsListItem {
            width: 100%;
            height: 150px;
            display: flex;
            align-items: center;
            justify-content: center;

            background-color: black;

            @include tablet {
              height: 150px;
              transition: all 0.5s ease-in-out;
            }

            @include largeMobile {
              height: 100px;
              transition: all 0.5s ease-in-out;
            }

            img {
              width: 100%;
              height: 100%;
              object-fit: fill;
              opacity: 85%;
            }
          }
        }

        .aboutPage__clientsBtn {
          width: 100%;
          display: flex;
          align-items: flex-end;
          justify-content: flex-end;
        }
      }
    }
  }
}
