.errorPage {
  display: flex;
  flex-direction: column;

  .errorPage__container {
    width: 100%;
    height: 950px;
    display: flex;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
