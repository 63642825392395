@import "../../../../styles/foundations/mixins.scss";

.prevNextArrowBtn {
  .prevNextArrowBtn__prev {
    height: 40px;
    width: 40px;
    transform: rotate(180deg);
    cursor: pointer;

    @include extraLargeTablet {
      height: 30px;
      width: 30px;
    }

    @include largeMobile {
      height: 25px;
      width: 25px;
    }

    &.deactiveBtn {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  .prevNextArrowBtn__next {
    height: 40px;
    width: 40px;
    cursor: pointer;

    @include extraLargeTablet {
      height: 30px;
      width: 30px;
    }

    @include largeMobile {
      height: 25px;
      width: 25px;
    }

    &.deactiveBtn {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}
