@import "../../../styles/foundations/mixins.scss";

.landingPartners {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  gap: 30px;

  @include wrapper1 {
    height: 100%;
    padding: 30px 0;
  }

  .landingPartners__container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    gap: 30px;

    // background-color: aquamarine;

    .landingPartnersTitleAndIcon {
      width: max-content;
      display: flex;
      flex-direction: column;

      .landingPartners__title {
        font-size: 56px;
        font-style: normal;
        font-weight: 700;

        @include wrapper1 {
          font-size: 36px;
        }

        @include tablet {
          font-size: 26px;
        }

        @include largeMobile {
          font-size: 20px;
        }
      }

      img {
        width: 100%;
        height: 5px;
      }
    }

    .landingPage__partnerList__container {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      gap: 30px;

      @include largeMobile {
        grid-template-columns: 1fr;
        gap: 15pxd;
      }

      .partnerList__card {
        height: 184px;
        width: 100%;
        display: flex;
        cursor: pointer;
        background-color: #d9d9d9;
        transition: transform 0.5s ease-in-out;

        padding: 40px;
        box-sizing: border-box;

        &:hover {
          transform: scale(1.01);
          transition: transform 0.5s ease-in-out;
          box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
            rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
        }

        @include largeMobile {
          height: 154px;
        }

        .partnerList__card_image {
          height: 100%;
          width: 100%;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            transition: transform 0.5s ease-in-out;
          }
        }
      }
    }

    .landingPartners__bottom {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }
}
