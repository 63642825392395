@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./styles/foundations/mixins.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Band color */
  --black: #000;
  --white: #fff;
  --yellow: #ffbf0f;

  /* primary color */
  --primary__color: #ffbf0f;

  /* background color */
  --bg__white: #fff;
  --bg__white__shade1: #bfbfbf;
  --bg__white__shade2: #d9d9d9;
  --bg__white__shade3: #e6e6e6;

  --bg__black: #000000;
  --bg__black__shade1: #2b2b2b;
  --bg__black__shade2: #404040;
  --bg__black__shade3: #595959;
  --bg__black__shade4: #8c8c8c;

  --bg__yellow: #ffbf0f;
  --bg__yellow__shade1: #fed24d;
  --bg__yellow__shade2: #fee18b;
  --bg__yellow__shade3: #fef1cb;

  --bg__input: #f3f3f3;

  /* text color */
  --text__white: #fff;
  --text__white__shade1: #bfbfbf;
  --text__white__shade2: #d9d9d9;
  --text__white__shade3: #e6e6e6;

  --text__black: #000000;
  --text__black__shade1: #2b2b2b;
  --text__black__shade2: #404040;
  --text__black__shade3: #595959;
  --text__black__shade4: #8c8c8c;

  --text__yellow: #ffbf0f;
  --text__yellow__shade1: #fed24d;
  --text__yellow__shade2: #fee18b;
  --text__yellow__shade3: #fef1cb;

  /* border color */
  --border__white: #ffffff;
  --border__whiteGray: #999999;
  --border__black: #000000;
  --border__black__shade1: #2b2b2b;
  --border__black__shade2: #404040;

  /* box-shadow color */
  --button__box__shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;

  --cardBox__shadow__black: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;

  /* inside-text-shadow color */
  --black__yellow__text__shadow: linear-gradient(
    101deg,
    #000000 2.08%,
    #febe13 58.14%
  );
  --white__yellow__text__shadow: linear-gradient(
    101deg,
    #ffffff 2.08%,
    #febe13 58.14%
  );

  -ms-overflow-style: none;
  scrollbar-width: none;
  overflow-y: scroll;
}

// body::-webkit-scrollbar {
//   display: none;
// }

// hide all scrollbar
::-webkit-scrollbar {
  display: none;
}

// remove number input arrow
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.link {
  text-decoration: none;
  color: inherit;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.common__headerLink__component {
  width: 100%;
  display: flex;
  gap: 20px;

  .common__headerLinkContainer {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 5px 10px;
    box-sizing: border-box;
    cursor: pointer;
    text-decoration: none;

    @include tablet {
      width: max-content;
      padding: 5px 2px;
      gap: 8px;
    }

    span {
      font-size: 16px;
      font-weight: 400;
      color: black;

      @include tablet {
        width: max-content;
      }

      @include miniPortraitTablet {
        font-size: 14px;
        width: max-content;
      }
    }

    i {
      font-size: 16px;
      color: #a1a0a0;
    }
  }

  .common__headerLinkContainer__active {
    color: var(--text__yellow);
  }
}

.primary__yellowBtn {
  border: none;
  outline: none;
  width: 100%;
  height: 100%;

  border-radius: 5px;
  padding: 10px 50px;

  font-size: 22px;
  font-weight: 400;
  text-decoration: none;

  background-color: var(--bg__black);
  color: var(--text__white);
  box-shadow: var(--button__box__shadow);
  cursor: pointer;
  transition: 0.3s ease-in-out;

  &:hover {
    background-color: var(--bg__yellow);
    transition: 0.3s ease-in-out;
  }

  &:active {
    background-color: var(--bg__yellow);
  }

  &:focus {
    outline: none;
  }

  @include largeMobile {
    padding: 10px 30px;
    font-size: 18px;
  }
}
