@import "./../../variables";

@import "../../styles/foundations/mixins.scss";

.headerContainer {
  background-color: $nav-color-black;
  display: flex;
  top: 0px;
  position: fixed;
  width: 100%;
  max-height: $top-mask;
  z-index: 1000;

  @include desktop {
    max-height: 100px;
  }

  .sidebarMobo {
    display: none;

    @media (max-width: $mobile) {
      display: block;
    }

    .bm-burger-button {
      position: fixed;
      height: 24px;
      right: 60px;
      top: 36px;

      @include desktop {
        height: 24px;
        right: 70px;
        top: 20px;
      }

      @include tablet {
        height: 24px;
        right: 40px;
        top: 20px;
      }

      @include largeMobile {
        height: 24px;
        right: 35px;
        top: 15px;
      }

      @include mobile {
        height: 24px;
        right: 20px;
        top: 15px;
      }
    }

    .bm-icon {
      min-width: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      gap: 0.5rem;
    }

    /* Color/shape of burger icon bars */
    .bm-burger-bars {
      background: #ffffff;
    }

    /* Color/shape of burger icon bars on hover*/
    .bm-burger-bars-hover {
      background: $primary-color;
    }

    /* Position and sizing of clickable cross button */
    .bm-cross-button {
      height: 34px;
      width: 34px;
    }

    /* Color/shape of close button cross */
    .bm-cross {
      background: $font-color-dark;
    }

    /*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
    .bm-menu-wrap {
      position: sticky;
      top: 0;
      height: 100%;
    }

    /* General sidebar styles */
    .bm-menu {
      background: $nav-color-black;
      padding: 2.5em 1.5em 0;
      font-size: 1.15em;
      overflow: hidden;
    }

    /* Morph shape necessary with bubble or elastic */
    .bm-morph-shape {
      fill: $font-color-dark;
    }

    /* Wrapper for item list */
    .bm-item-list {
      color: #b8b7ad;
      // padding: 0.8em;
    }

    /* Individual item */
    .bm-item {
      display: inline-block;
      text-decoration: none;
      padding: 10px 0;
      // font-size: 1.4rem;
      font-size: 18px;
      //font not break
      // white-space: nowrap;
      color: #818181;
      &:hover {
        color: #fbbf24;
      }
    }

    /* Styling of overlay */
    .bm-overlay {
      background: rgba(0, 0, 0, 0.3);
    }
  }

  .mySidenav {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 10px 0;
    box-sizing: border-box;

    .sidebarContainer {
      width: 83.33%;
      display: flex;

      @media screen and (max-width: 1600px) {
        width: 95%;
      }

      .left {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        img {
          width: 100%;
          height: 45px;
          object-fit: contain;
          padding-right: 12rem;
          cursor: pointer;
          transition: all 0.3s ease-in-out;

          @media screen and (max-width: 1600px) {
            padding-right: 6rem;
          }

          @include mediumTablet {
            padding-right: 0;
            height: 40px;
            transition: all 0.3s ease-in-out;
          }

          @include mobile {
            padding-right: 0;
            height: 35px;
          }
        }
      }

      .right {
        flex: 1;
        position: relative;

        .content {
          position: absolute;
          top: 0;
          right: 0;

          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;

          background-color: #000;

          .search {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            height: 100%;

            .search__inputForm {
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: flex-end;

              input {
                width: 50%;
                border-bottom: 1px solid #ffffff;
                outline: none;
                background-color: transparent;
                color: #ffffff;
                font-size: 1rem;
                padding: 0 0 3px 10px;

                &::placeholder {
                  color: #5c5c5c;
                }
              }

              i {
                fill: #ffffff;
                color: #ffffff;
                cursor: pointer;
                transition: all 0.3s ease-in-out;
              }
            }
          }
        }

        .top {
          display: flex;
          align-items: flex-end;
          justify-content: flex-end !important;
          color: #ffffff;

          @media (max-width: $mobile) {
            display: none;
          }

          .topContainer {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: flex-end;
            justify-content: flex-end !important;
            color: #ffffff;
            list-style-type: none;
            justify-content: space-between;
            align-items: center;
            gap: 1.5rem;
            // margin-bottom: 1.5rem;
            // margin-top: 1rem;

            .search-container {
              display: flex;
              justify-content: center;
              align-items: center;
              margin-left: 0.35rem;
              margin-right: 0.75rem;
              font-size: 0.875rem;
              line-height: 1.25rem;
              max-width: 300px;
              flex: 1 1 0%;
              border-bottom: 1px solid #ccc;

              &:hover {
                color: #fbbf24;
                border-bottom: 1px solid #fbbf24;
              }

              input {
                display: block;
                padding: 0.625rem;
                padding-left: 0.5rem;
                background-color: transparent;
                color: #ffffff;
                font-size: 0.875rem;
                line-height: 1.25rem;
                width: 100%;
                border: none;
                outline: none;
              }
            }

            .shopLogo {
              cursor: pointer;
            }

            .topItemOpt {
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 1rem;

              .topItem {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 0.5rem;
                cursor: pointer;

                &:hover {
                  color: #fbbf24;
                }
              }
            }

            .languageSelect {
              gap: 0;

              select {
                background-color: transparent;
                color: #ffffff;
                font-size: 0.875rem;
                line-height: 1.25rem;
                border: none;
                outline: none;
                padding: 0.625rem;
                padding-left: 0.5rem;
                &:hover {
                  color: #fbbf24;
                }
              }
            }
          }
        }

        //hide top with keyframes
        .topHide {
          display: none;
          animation: hideTop 0.5s ease-in-out forwards;
        }

        *,
        *::before,
        *::after {
          box-sizing: border-box;
        }

        .bottom {
          display: flex;
          align-items: flex-end;
          justify-content: flex-end;
          margin-bottom: 0.75rem;
          color: #ffffff;
          list-style-type: none;
          flex-direction: row;
          gap: 20px;
          // margin-top: 1.5rem;
          margin-top: 20px;
          padding-left: 0;

          @media (max-width: $mobile) {
            display: none;
          }

          .whitespace-nowrap {
            text-transform: uppercase;
            font-size: 0.875rem;
            text-align: center;
            line-height: 1.25rem;
            margin-left: 0.5rem;
            cursor: pointer;
            position: relative;
            transition: all 0.4s ease-in-out;

            @media screen and (max-width: 1400px) {
              font-size: 0.75rem;
            }

            &:hover {
              color: white;
            }

            sub {
              padding-left: 5px;
            }

            .search-icon {
              position: relative;
              display: inline-block;

              input[type="text"] {
                position: absolute;
                top: 0;
                transform: translate(-100%, 0);
                left: 0;
                width: 200px;
                padding: 8px;
                background-color: #f9f9f9;
                border: 1px solid #ccc;
                border-top: none;

                visibility: hidden;
                opacity: 0;
                transition: all 0.2s ease-in-out;
              }

              i {
                cursor: pointer;
              }

              &:hover {
                input[type="text"] {
                  visibility: visible;
                  opacity: 1;
                }
              }
            }
          }
        }

        .notHovered {
          color: gray;
        }
      }
    }
  }

  .full-screen-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: transparent;
    //transparency

    .overlay-content {
      position: absolute;
      top: 86px;
      background-color: #000;
      height: 100%;
      width: 50%;
      z-index: 9999999;

      .overlay__content__inner {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .overlay__content__innerBlock {
          width: 1550px;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          .overlay__content__innerBlock__contentContainer {
            width: 100%;
            height: 90%;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-gap: 25px;
            overflow-y: scroll;

            padding: 20px 0 100px 0;
            box-sizing: border-box;

            .overlay__content__innerBlock__contentContainer__Block {
              width: 100%;
              height: max-content;
              display: flex;
              flex-direction: column;
              gap: 20px;

              border-right: 1px solid #646464;

              // background-color: #b8b7ad;

              .innerBlock__productContent__titele {
                display: flex;
                margin-bottom: 20px;

                h1 {
                  font-size: 1.5rem;
                  line-height: 1.5rem;
                  color: #ffffff;
                  font-weight: 400;
                  margin-bottom: 1rem;
                }
              }

              .productContent__container {
                display: flex;
                gap: 20px;
                align-items: center;

                padding-right: 20px;
                box-sizing: border-box;

                .productContent__container__left {
                  display: flex;

                  .productContent__container__leftImg {
                    display: flex;
                    width: 180px;
                    height: 220px;
                    padding: 20px;
                    box-sizing: border-box;
                    // border-radius: 5px;

                    background-color: #f9f9f9;

                    img {
                      width: 100%;
                      height: 100%;
                      object-fit: contain;
                    }
                  }
                }

                .BrochureContent__container__left {
                  display: flex;

                  .BrochureContent__container__leftImg {
                    display: flex;
                    width: 180px;
                    height: 220px;

                    img {
                      width: 100%;
                      height: 100%;
                      object-fit: contain;
                    }
                  }
                }

                .productContent__container__right1 {
                  display: flex;
                  flex-direction: column;
                  gap: 15px;

                  .productContent__container__right__title1 {
                    span {
                      font-size: 22px;
                      color: #ffffff;
                      font-weight: 700;
                    }
                  }

                  .productContent__container__right__desc {
                    span {
                      font-size: 16px;
                      color: #ffffff;
                      font-weight: 400;
                      text-align: justify;
                    }
                  }
                }
              }

              .sNewsContent__container {
                display: flex;
                flex-direction: column;
                gap: 15px;

                margin-right: 15px;
                padding: 0 0 15px 0;
                box-sizing: border-box;
                border-bottom: 1px solid #646464;

                .sNewsContent__container__title {
                  span {
                    font-size: 22px;
                    color: #ffffff;
                    font-weight: 700;
                  }
                }

                .sNewsContent__container__desc {
                  span {
                    font-size: 16px;
                    color: #ffffff;
                    font-weight: 400;
                    text-align: justify;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
