@import "../../../styles/foundations/mixins.scss";

.newsAndEventsPage111 {
  display: flex;
  flex-direction: column;

  .newsAndEventsPage__container {
    width: 100%;
    display: flex;
    flex-direction: column;

    padding: 64px 0;
    box-sizing: border-box;

    @include tablet {
      padding: 30px 0 40px 0;
      transition: all 0.5s ease-in-out;
    }

    @include largeMobile {
      padding: 20px 0 40px 0;
    }

    .newsAndEvents__content {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 30px;
      margin-bottom: 30px;

      @include extraLargeTablet {
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;
      }

      @include mediumTablet {
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
      }

      @include miniPortraitTablet {
        grid-template-columns: repeat(1, 1fr);
        gap: 20px;
      }

      .newsAndEvents__content__card {
        width: 100%;
        height: 550px;
        display: flex;
        flex-direction: column;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;

        overflow: hidden;
        cursor: pointer;
        transition: all 0.3s ease-in-out;

        &:hover {
          box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 12px;
          transform: scale(1.02);
          transition: all 0.3s ease-in-out;
        }

        @include tablet {
          height: 100%;
        }

        .newsAndEvents__content__cardImg {
          width: 100%;
          height: 280px;
          display: flex;
          background-color: var(--black);

          @include tablet {
            height: 200px;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            opacity: 75%;
          }
        }

        .newsAndEvents__content__card__container {
          width: 100%;
          height: 192px;
          display: flex;
          flex-direction: column;

          gap: 15px;
          padding: 20px;
          box-sizing: border-box;

          @include tablet {
            height: 100%;
          }

          .newsAndEvents__content__cardDateAndtype {
            width: 100%;
            display: flex;
            justify-content: space-between;

            span {
              color: #000;
              font-size: 16px;
              line-height: 16px;

              @include tablet {
                font-size: 13px;
              }
            }
          }

          .newsAndEvents__content__card__title {
            span {
              color: var(--text__black);
              font-size: 20px;
              font-weight: 700;
              line-height: 24px;

              @include tablet {
                font-size: 16px;
              }
            }
          }

          .newsAndEvents__content__card__description {
            span {
              color: var(--text__black);
              font-size: 16px;
              line-height: 22px;
              text-align: justify;

              @include tablet {
                font-size: 13px;
              }
            }
          }
        }
      }

      .eventBg {
        background-color: #ffb600;
        .newsAndEvents__content__card__container {
          .newsAndEvents__content__cardDateAndtype {
            span {
              color: var(--text__black);
            }
          }
        }
      }

      .newsAndEvents__content__card:nth-child(1) {
        grid-column-start: span 2;

        @include extraLargeTablet {
          grid-column-start: span 2;
        }

        @include mediumTablet {
          grid-column-start: span 1;
        }

        @include miniPortraitTablet {
          grid-column-start: span 1;
        }
      }

      .newsAndEvents__content__card:nth-child(3) {
        grid-column-start: span 1;

        @include mediumTablet {
          grid-column-start: span 2;
        }

        @include miniPortraitTablet {
          grid-column-start: span 1;
        }
      }

      .newsAndEvents__content__card:nth-child(6) {
        grid-column-start: span 1;

        @include mediumTablet {
          grid-column-start: span 2;
        }

        @include miniPortraitTablet {
          grid-column-start: span 1;
        }
      }

      .newsAndEvents__content__card:nth-child(8) {
        grid-column-start: span 2;

        @include extraLargeTablet {
          grid-column-start: span 1;
        }
      }

      .newsAndEvents__content__card:nth-child(9) {
        grid-column-start: span 2;

        @include extraLargeTablet {
          grid-column-start: span 3;
        }

        @include mediumTablet {
          grid-column-start: span 2;
        }

        @include miniPortraitTablet {
          grid-column-start: span 1;
        }
      }
    }
  }
}
