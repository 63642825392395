@import "../../../styles/foundations/mixins.scss";

.ProductPageContainer {
  display: flex;
  flex-direction: column;

  .containerItems {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .containerMin {
      width: 100%;
      height: 100%;

      padding: 64px 0;
      box-sizing: border-box;

      @include tablet {
        padding: 30px 0 40px 0;
        transition: all 0.5s ease-in-out;
      }

      @include largeMobile {
        padding: 20px 0 40px 0;
      }

      .content {
        width: 100%;
        height: 100%;

        .contentTop {
          // display: flex;
          gap: 30px;

          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(370px, 1fr));

          @include largeMobile {
            gap: 15px;
            grid-template-columns: 1fr;
            transition: all 0.3s ease-in-out;
          }

          .contentBtnBox {
            width: 100%;
            border: 1px solid #e5e5e5;
            display: flex;
            justify-content: space-between;
            flex: 1;
            height: 90px;
            align-items: center;
            padding: 0 30px;
            box-sizing: border-box;
            gap: 20px;
            cursor: pointer;
            transition: all 0.3s ease-in-out;
            color: #e5e5e5;

            @include extraLargeTablet {
              height: 70px;
              transition: all 0.3s ease-in-out;
            }

            @include tablet {
              height: 60px;
              padding: 0 20px;
              transition: all 0.3s ease-in-out;
            }

            @include largeMobile {
              justify-content: space-between;
              height: 50px;
              transition: all 0.3s ease-in-out;
            }

            .c {
              display: flex;
              align-items: center;
              gap: 20px;

              img {
                width: 48px;
                height: 48px;
                object-fit: cover;
                transition: all 0.3s ease-in-out;

                @include extraLargeTablet {
                  width: 38px;
                  height: 38px;
                  transition: all 0.3s ease-in-out;
                }

                @include tablet {
                  width: 28px;
                  height: 28px;
                  transition: all 0.3s ease-in-out;
                }

                @include largeMobile {
                  width: 18px;
                  height: 18px;
                  transition: all 0.3s ease-in-out;
                }
              }

              h2 {
                font-size: 25px;
                font-weight: 600;

                @include extraLargeTablet {
                  font-size: 22px;
                  transition: all 0.3s ease-in-out;
                }

                @include tablet {
                  font-size: 20px;
                  transition: all 0.3s ease-in-out;
                }

                @include largeMobile {
                  font-size: 18px;
                  transition: all 0.3s ease-in-out;
                }
              }
            }

            i {
              font-size: 25px;

              @include extraLargeTablet {
                font-size: 22px;
                transition: all 0.3s ease-in-out;
              }

              @include tablet {
                font-size: 20px;
                transition: all 0.3s ease-in-out;
              }

              @include largeMobile {
                font-size: 18px;
                transition: all 0.3s ease-in-out;
              }
            }
          }

          .active {
            transition: all 0.3s ease-in-out;
            color: black;

            i {
              animation: rotate 0.5s linear;
              transition: all 0.3s ease-in-out;
            }
          }
        }

        .contentBottom {
          margin-top: 30px;
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
          // grid-template-columns: repeat(auto-fit, minmax(370px, 1fr));
          // grid-template-columns: 1fr 1fr 1fr;
          gap: 30px;

          @include largeMobile {
            gap: 20px;
            grid-template-columns: 1fr;
            transition: all 0.3s ease-in-out;
          }

          .contentBottomBox {
            border: 1px solid #e5e5e5;
            height: 200px;
            padding: 30px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            cursor: pointer;

            &:hover {
              transition: all 0.3s ease-in-out;

              .contentBottomBtn {
                .iconBtnBlack {
                  svg {
                    #Vector_1 {
                      fill: orange;
                      transition: all 0.1s ease-in-out;
                    }
                    #Vector_2 {
                      fill: orange;
                      transition: all 0.2s ease-in-out;
                    }
                    #Vector_4 {
                      fill: orange;
                      transition: all 0.4s ease-in-out;
                    }
                    #Vector_3 {
                      fill: orange;
                      transition: all 0.5s ease-in-out;
                    }
                    #Vector_5 {
                      fill: orange;
                      transition: all 0.7s ease-in-out;
                    }
                  }
                }
              }
            }

            img {
              width: 100%;
              height: 120px;
              object-fit: contain;
            }

            .contentBottomBtn {
              width: 100%;
              display: flex;
              justify-content: flex-end;
            }
          }

          .SegmentBottomBox {
            height: 200px;
            position: relative;
            cursor: pointer;
            transition: all 0.3s ease-in-out;
            background-color: black;

            &:hover {
              transform: scale(1.04);
              transition: all 0.3s ease-in-out;
            }

            img {
              height: 100%;
              width: 100%;
              object-fit: cover;
              opacity: 65%;
            }

            .SegmentBottomBoxSpan {
              position: absolute;
              bottom: 0;
              left: 0;
              margin: 10px;
              padding: 5px 10px;
              opacity: 100%;
              background: rgba(255, 255, 255, 0.45);
            }
          }
        }
      }
    }
  }

  .productDetailsContainer {
    width: 100%;
    max-width: 1620px;

    .productDetailsContainerTop {
      margin-bottom: 64px;
      display: flex;
      flex-direction: column;
      gap: 10px;

      h1 {
        font-family: "Inter";
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 24px;
      }

      p {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 36px;
      }
    }
    .productDetailsContainerBottom {
      display: flex;
      gap: 70px;

      .prodLeft {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 440px;
        width: 425px;
        border: 5px solid #ffd96f;

        .prodImg {
          width: 234px;
          height: 308px;
          object-fit: cover;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }

      .prodRight {
        flex: 1;
        .prodRightTop {
          display: flex;
          gap: 45px;
          width: 100%;
          padding-bottom: 8px;
          border-bottom: 3px solid #e5e5e5;
          margin-bottom: 15px;

          span {
            position: relative;
            font-family: "Inter";
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            cursor: pointer;

            i {
              position: absolute;
              right: -20px;
              top: 25px;
              font-size: 20px;
              background-color: #ffffff;
              color: #e5e5e5;
            }
          }
        }

        .prodRightBottom {
          .prodRightBottomDescItem {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            gap: 30px;

            .prodRightBottomDescItemLink {
              grid-row: 1fr;
              border-radius: 10px;
              display: flex;
              flex-direction: row;
              align-items: center;
              height: 100px;
              box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
                rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
              position: relative;
              font-family: "Inter";
              font-style: normal;
              font-weight: 700;
              font-size: 16px;
              line-height: 24px;

              i {
                width: 85px;
                font-size: 40px;
                text-align: center;
              }
            }
          }
        }
      }
    }
  }
}

@keyframes rotate {
  0% {
    transform: rotate(-180deg);
  }
  50% {
    transform: rotate(-90deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
