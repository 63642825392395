@import "../../../styles/foundations/mixins.scss";

.referenceProject__container {
  width: 100%;
  display: flex;
  flex-direction: column;

  padding: 64px 0;
  box-sizing: border-box;

  @include tablet {
    padding: 30px 0 40px 0;
    transition: all 0.5s ease-in-out;
  }

  @include largeMobile {
    padding: 20px 0 40px 0;
  }

  .referenceProject__Block {
    display: grid;
    // grid-template-columns: repeat(3, 570px);
    grid-template-columns: repeat(auto-fit, minmax(370px, 1fr));
    gap: 30px;

    @include largeMobile {
      grid-template-columns: 1fr;
      gap: 15px;
    }

    .referenceProdect__card {
      width: 100%;
      // height: 450px;
      height: 400px;
      position: relative;
      display: flex;
      background-color: var(--black);
      transition: 0.5s ease-in-out;

      @include mediumTablet {
        // height: 400px;
        height: 350px;
        transition: 0.5s ease-in-out;
      }

      @include miniPortraitTablet {
        // height: 400px;
        height: 200px;
        transition: 0.5s ease-in-out;
      }

      .referenceProdect__card__image {
        width: 100%;
        height: 100%;
        opacity: 90%;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          opacity: 65%;
        }
      }

      .referenceProdect__card__content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        // padding: 48px;
        padding: 30px;
        box-sizing: border-box;

        .referenceProdect__card__content__title {
          display: flex;
          flex-direction: column;

          span {
            // font-weight: 700;
            // font-size: 48px;
            // line-height: 58px;
            // padding-bottom: 15px;

            font-weight: 700;
            font-size: 30px;
            line-height: 40px;
            padding-bottom: 10px;

            color: var(--text__white);
            transition: 0.5s ease-in-out;

            @include largeMobile {
              // font-size: 36px;
              // line-height: 46px;
              // padding-bottom: 10px;

              font-size: 22px;
              line-height: 24px;
              padding-bottom: 5px;
              transition: 0.5s ease-in-out;
            }
          }

          .referenceProdect__card__contentUnderline {
            width: 100px;
            height: 1.5px;
            background-color: white;
          }
        }

        .referenceProdect__card__content__button {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-end;

          .referenceProdect__card__content__button__link {
            display: flex;
            align-items: center;
            gap: 10px;
            cursor: pointer;

            @include largeMobile {
              gap: 5px;
            }

            span {
              font-weight: 700;
              font-size: 24px;
              line-height: 24px;
              color: white;
              padding-bottom: 5px;

              @include largeMobile {
                font-size: 18px;
                line-height: 18px;
                padding-bottom: 0px;
              }
            }

            img {
              height: 100%;
              width: 100%;
              object-fit: cover;

              @include largeMobile {
                height: 18px;
                width: 60%;
              }
            }
          }
        }
      }
    }
  }
}
