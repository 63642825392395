@import "../../../styles/foundations/mixins.scss";

.carousal_test {
  position: relative;
  width: 100%;
  height: 100%;

  .cariusel__swiper {
    width: 100%;
    height: 610px;
    // height: auto;
    display: flex;
    transition: all 0.5s ease-in-out;

    @include wrapper1 {
      height: 450px;
      transition: all 0.5s ease-in-out;
    }

    @include mediumTablet {
      height: 350px;
      transition: all 0.5s ease-in-out;
    }

    @include miniPortraitTablet {
      height: 225px;
      transition: all 0.5s ease-in-out;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: fill;
    }
  }

  .carousel__dummy {
    width: 100%;
    height: 185px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 70px;

    @include mediumTablet {
      height: 150px;
    }

    .side_index {
      display: flex;
      align-items: center;

      @include wrapper1 {
        display: none;
      }

      .current_slide {
        height: 20px;
        text-align: center;
        line-height: 20px;
        font-weight: 600;
        padding: 0 10px;
        z-index: 222;
      }

      .side_index_item {
        width: 20px;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        z-index: 222;
      }

      .play_pause {
        width: 50px;
        height: 50px;

        border-radius: 10px;
        border: 1px solid #ffbf10;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 10px;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
        z-index: 222;

        &:hover {
          background: #000;
          color: #fff;
        }

        i {
          font-size: 20px;
        }
      }
    }
  }

  .carousel__textContainer {
    position: absolute;
    bottom: 40px;
    left: 0;
    width: 100%;
    // height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;

    .carousel__text {
      width: 1170px;
      display: flex;
      flex-direction: column;

      @include wrapper1 {
        width: 90%;
        height: 100%;
      }

      .carousel__textDiv {
        width: 1000px;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        padding: 30px 60px 30px 30px;
        background: rgba(28, 28, 28, 0.85);
        gap: 40px;

        @include wrapper1 {
          width: 100%;
          height: 100%;
          padding: 30px 50px 30px 30px;
        }

        @include mediumTablet {
          gap: 30px;
        }

        @include miniPortraitTablet {
          gap: 15px;
          padding: 20px 40px 20px 20px;
        }

        // .cariusel__swipertext {
        //   width: 100%;
        //   height: 100%;

        //   .cs {
        //     width: 100%;
        //     height: 100%;
        //     display: flex;
        //     flex-direction: column;
        //     gap: 40px;

        //     @include mediumTablet {
        //       gap: 30px;
        //     }

        //     @include miniPortraitTablet {
        //       gap: 15px;
        //     }

        //     .carousel__textHeader {
        //       font-size: 48px;
        //       font-style: normal;
        //       font-weight: 700;
        //       line-height: 60px;
        //       color: white;

        //       @include wrapper1 {
        //         font-size: 45px;
        //         line-height: 50px;
        //       }

        //       @include mediumTablet {
        //         font-size: 32px;
        //         line-height: 40px;
        //       }

        //       @include miniPortraitTablet {
        //         font-size: 20px;
        //         line-height: 25px;
        //       }
        //     }

        //     .carousel__textDesc {
        //       font-size: 24px;
        //       font-weight: 400;
        //       line-height: 36px;
        //       color: white;

        //       @include wrapper1 {
        //         font-size: 20px;
        //         line-height: 30px;
        //       }

        //       @include mediumTablet {
        //         font-size: 16px;
        //         line-height: 24px;
        //       }

        //       @include miniPortraitTablet {
        //         font-size: 14px;
        //         line-height: 21px;
        //       }
        //     }
        //   }
        // }

        .carousel__textHeader {
          font-size: 48px;
          font-style: normal;
          font-weight: 700;
          line-height: 60px;
          color: white;

          animation: fadeTextAnimation 0.5s ease-in-out;

          @include wrapper1 {
            font-size: 45px;
            line-height: 50px;
          }

          @include mediumTablet {
            font-size: 32px;
            line-height: 40px;
          }

          @include miniPortraitTablet {
            font-size: 20px;
            line-height: 25px;
          }
        }

        .carousel__textDesc {
          font-size: 24px;
          font-weight: 400;
          line-height: 36px;
          color: white;

          @include wrapper1 {
            font-size: 20px;
            line-height: 30px;
          }

          @include mediumTablet {
            font-size: 16px;
            line-height: 24px;
          }

          @include miniPortraitTablet {
            font-size: 14px;
            line-height: 21px;
          }
        }

        .animate__animated {
          animation: fadeInTextAnimation 2s ease-in-out;
        }
      }
    }
  }
}

@keyframes fadeInTextAnimation {
  // add fancy text animation here
  0% {
    opacity: 0;
    // transform: perspective(2000px) rotateX(180deg);
    // transform: scaleX(0);
  }
  100% {
    opacity: 1;
    // transform: perspective(1000px) rotateX(0deg);
    // transform: scaleX(1);
  }
}
