@import "../../styles/foundations/mixins.scss";

.download {
  display: flex;
  flex-direction: column;

  .download__container {
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 50px 0;
    box-sizing: border-box;

    @include extraLargeTablet {
      padding: 60px 0;
    }

    @include portraitTablet {
      padding: 40px 0;
    }

    @include largeMobile {
      padding: 30px 0;
    }
  }
}

.download__list {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .download__list__items {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    row-gap: 20px;
    column-gap: 20px;

    .download__list__item {
      height: 100%;

      img {
        width: 100%;
        height: 100px;
      }
    }
  }
}
