@import "../../../styles/foundations/mixins.scss";

.wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .wrapperContent {
    width: 1170px;
    display: flex;
    flex-direction: column;
    transition: all 0.5s ease-in-out;

    @include wrapper1 {
      width: 100%;
      height: 100%;

      padding: 0 50px;
      transition: all 0.5s ease-in-out;
    }

    @include portraitTablet {
      padding: 0 20px;
      transition: all 0.5s ease-in-out;
    }
  }
}
