@import "../../../styles/foundations/mixins.scss";

.landingCareer {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  gap: 30px;

  @include wrapper1 {
    height: 100%;

    padding: 50px 0;
  }

  .landingCareer__conatiner {
    display: grid;
    align-items: center;
    grid-template-columns: 0.8fr 1fr;
    gap: 60px;

    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);

    @include smallDesktop {
      grid-template-columns: 1fr;
      gap: 30px;

      .landingCareer__conatiner__left {
        order: 2;
      }
    }

    .landingCareer__conatiner__left {
      width: 100%;
      height: 450px;
      background-color: black;

      @include smallDesktop {
        height: 300px;
      }

      @include portraitTablet {
        height: 250px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: fill;
        opacity: 85%;
      }
    }

    .landingCareer__conatiner__right {
      width: 80%;
      display: flex;
      flex-direction: column;
      gap: 30px;

      @include smallDesktop {
        padding: 40px 40px;
      }

      @include portraitTablet {
        width: 100%;
        padding: 40px 20px;
      }

      .endTextGradient {
        font-family: "Inter";
        font-style: normal;
        font-weight: 700;
        font-size: 48px;
        line-height: 58px;

        // background: linear-gradient(101deg, #010101 2.08%, #febe13 58.14%);
        background: var(--black__yellow__text__shadow);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        -webkit-text-fill-color: transparent;

        @include smallDesktop {
          font-size: 36px;
          line-height: 46px;
        }

        @include portraitTablet {
          font-size: 32px;
          line-height: 42px;
        }
      }

      .landingCareer__btn {
        display: flex;
        align-items: center;
        gap: 10px;
        cursor: pointer;
        transition: color 0.3s ease-in-out;

        &:hover {
          .landingCareer__btntext {
            color: orange;
            transition: color 0.3s ease-in-out;
          }

          .landingCareer__btnIcon {
            background-image: url("../../../assets/images/common_icons/btn__yellowIcon.png");
            width: 35px;
            height: 15px;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
            transition: all 0.3s ease-in-out;
          }
        }

        .landingCareer__btntext {
          font-size: 24px;
          font-weight: 400;

          @include smallDesktop {
            font-size: 20px;
          }

          @include portraitTablet {
            font-size: 18px;
          }
        }

        .landingCareer__btnIcon {
          margin-top: 5px;
          background-image: url("../../../assets//images//common_icons/btn__arrowBlack.png");
          width: 40px;
          height: 15px;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          transition: all 0.3s ease-in-out;
        }
      }
    }
  }
}
