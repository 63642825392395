@import "../../../styles/foundations/mixins.scss";

.workWithUs {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  .workWithUs__about {
    width: 100%;
    display: flex;
    flex-direction: column;

    padding: 64px 0;
    box-sizing: border-box;

    @include miniPortraitTablet {
      padding: 20px 0;
    }
  }

  .workWithUs__factAndFigures {
    height: 600px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    @include desktop {
      height: 100%;
    }
  }

  .workWithUs__container {
    width: 100%;
    display: flex;
    flex-direction: column;

    padding: 84px 0;
    box-sizing: border-box;

    .workWithUs__deptContainer {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 64px;

      .workWithUs__deptCard {
        height: 100%;
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 0.7fr;

        gap: 64px;
        background-color: rgba(240, 240, 240, 0.45);
        // background-color: rgba(116, 24, 24, 0.45);
        border-radius: 10px;
        transition: all 0.3s ease-in-out;

        &:hover {
          transform: scale(1.005);
          transition: all 0.3s ease-in-out;
          box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.75);
        }

        @include largeTablet {
          grid-template-columns: 1fr;
          gap: 30px;
          transition: all 0.5s ease-in-out;
        }

        .workWithUs__deptCard__left {
          height: 100%;
          width: 100%;
          display: flex;
          flex-direction: column;

          gap: 20px;
          padding: 48px;
          box-sizing: border-box;

          // background-color: aqua;

          @include largeTablet {
            padding: 8px;
          }

          .workWithUs__deptCard__leftHeader {
            span {
              font-weight: 700;
              font-size: 32px;
              line-height: 24px;
            }
          }

          .workWithUs__deptCard__leftDesc {
            span {
              font-weight: 400;
              font-size: 16px;
              line-height: 24px;
            }
          }

          .workWithUs__deptCard__leftPositionLinkContainer {
            display: flex;
            flex-direction: column;

            margin-left: 20px;

            .workWithUs__deptCard__leftPositionLink {
              display: flex;
              align-items: center;
              gap: 5px;

              .workWithUs__deptCard__leftPositionLinkDot {
                height: 5px;
                width: 5px;
                border-radius: 50%;
                background-color: black;
              }

              span {
                font-weight: 700;
                font-size: 16px;
                line-height: 24px;
              }
            }
          }

          .workWithUs__deptCard__btn {
            width: 100%;
            margin-top: 10px;
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;
          }
        }

        .workWithUs__deptCard__right {
          height: 100%;
          width: 100%;
          display: flex;
          flex-direction: column;

          padding: 8px;
          box-sizing: border-box;

          // background-color: rgb(255, 115, 0);

          .workWithUs__deptCard__rightImg {
            height: 300px;
            width: 100%;
            display: flex;
            flex-direction: column;
            border-radius: 10px;
            background-color: black;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 10px;
              opacity: 65%;
            }
          }
        }
      }

      .workWithUs__deptCard:nth-child(2n) {
        grid-template-columns: 0.7fr 1fr;

        @include largeTablet {
          grid-template-columns: 1fr;
        }

        .workWithUs__deptCard__left {
          order: 2;

          @include largeTablet {
            order: 1;
          }
        }

        .workWithUs__deptCard__right {
          order: 1;

          @include largeTablet {
            order: 2;
          }
        }
      }
    }
  }
}

.TextGradient {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;

  background: linear-gradient(101deg, #1b1b1b 2.08%, #febe13 58.14%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
