@import "../../../styles/foundations/mixins.scss";

.productListPage {
  display: flex;
  flex-direction: column;

  .productListPage__container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;

    padding: 40px 0;
    box-sizing: border-box;

    @include tablet {
      padding: 30px 0 40px 0;
      transition: all 0.5s ease-in-out;
    }

    @include largeMobile {
      padding: 20px 0 40px 0;
    }
  }
}
