@import "../../../styles/foundations/mixins.scss";

.newsAndEventsDetails {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .newsAndEventsDetails__container {
    width: 100%;
    display: flex;
    flex-direction: column;

    padding: 64px 0;
    box-sizing: border-box;

    @include tablet {
      padding: 30px 0 40px 0;
    }

    @include largeMobile {
      padding: 20px 0 40px 0;
    }

    .newsAndEventsDetails__headerAndImg {
      height: 100%;
      display: flex;
      gap: 40px;
      margin-bottom: 50px;

      @include smallDesktop {
        flex-direction: column-reverse;

        gap: 20px;
        margin-bottom: 30px;
      }

      @include tablet {
        flex: 1;
        gap: 25px;
        margin-bottom: 30px;
      }

      @include largeMobile {
        gap: 10px;
        margin-bottom: 20px;
      }

      .newsAndEventsDetails__headerLeft {
        display: flex;
        flex-direction: column;
        gap: 40px;

        @include smallDesktop {
          gap: 20px;
        }

        @include tablet {
          gap: 15px;
        }

        .newsAndEventsDetails__header__date {
          font-weight: 700;
          font-size: 16px;
          line-height: 19px;

          @include tablet {
            font-size: 14px;
          }

          @include largeMobile {
            font-size: 12px;
          }
        }

        .newsAndEventsDetails__header__title {
          h1 {
            font-weight: 700;
            font-size: 32px;
            line-height: 39px;

            @include tablet {
              font-size: 24px;
              line-height: 29px;
            }

            @include largeMobile {
              font-size: 20px;
              line-height: 24px;
            }
          }
        }

        .newsAndEventsDetails__ReporterContent {
          display: flex;
          align-items: center;
          gap: 30px;

          @include tablet {
            gap: 20px;
          }

          @include largeMobile {
            gap: 10px;
          }

          .newsAndEventsDetails__ReporterImg {
            height: 64px;
            width: 64px;

            @include tablet {
              height: 50px;
              width: 50px;
            }

            @include largeMobile {
              height: 30px;
              width: 30px;
            }

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 50%;
            }
          }

          .newsAndEventsDetails__ReporterInfo {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 5px;

            .newsAndEventsDetails__ReporterInfo__name {
              font-weight: 700;
              font-size: 20px;
              line-height: 29px;
              color: rgb(124, 124, 124);

              @include tablet {
                font-size: 16px;
                line-height: 19px;
              }

              @include largeMobile {
                font-size: 12px;
                line-height: 17px;
              }
            }

            .newsAndEventsDetails__ReporterSocial {
              display: flex;
              align-items: center;
              gap: 15px;

              @include tablet {
                gap: 10px;
              }

              @include largeMobile {
                gap: 5px;
              }

              .ReporterSocial__icon {
                height: 30px;
                width: 30px;

                display: flex;
                align-items: center;
                justify-content: center;

                padding: 5px;
                box-sizing: border-box;
                border: 1px solid rgb(124, 124, 124);
                border-radius: 10px;
                cursor: pointer;

                &:hover {
                  color: orange;
                  transition: all 0.3s ease-in-out;

                  border: 1px solid orange;

                  i {
                    color: orange;
                    transition: all 0.3s ease-in-out;
                  }
                }

                @include tablet {
                  height: 25px;
                  width: 25px;
                  border-radius: 8px;
                }

                @include largeMobile {
                  height: 20px;
                  width: 20px;
                  border-radius: 5px;
                }

                i {
                  font-size: 16px;
                  color: rgb(124, 124, 124);
                  transition: all 0.3s ease-in-out;

                  @include tablet {
                    font-size: 12px;
                  }

                  @include largeMobile {
                    font-size: 10px;
                  }
                }
              }
            }
          }
        }
      }

      img {
        float: left;
        width: 600px;
        object-fit: fill;

        @include smallDesktop {
          float: none;
        }
      }
    }
  }

  .newsAndEventsDetails__image2 {
    width: 100%;
    height: 550px;
    display: flex;
    flex-direction: column;

    padding: 62px 0 0 0;
    box-sizing: border-box;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .newsAndEventsDetails__descPart2 {
    width: 100%;
    display: flex;
    flex-direction: column;

    padding: 62px 0 0 0;
    box-sizing: border-box;
  }

  .newsAndEventsDetails__imageAndContent {
    //   width: 100%;
    //   display: flex;
    //   flex-direction: column;

    padding: 62px 0 0 0;
    box-sizing: border-box;

    .newsAndEventsDetails__imageAndContent__img {
      width: 400px;
      height: 380px;
      float: left;

      padding: 0 30px 20px 0;
      box-sizing: border-box;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    p {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: justify;
    }
  }
}
