@import "../../styles/foundations/mixins.scss";

.downloadPage {
  display: flex;
  flex-direction: column;

  .downloadPage__container {
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 50px 0;
    box-sizing: border-box;

    @include extraLargeTablet {
      padding: 60px 0;
    }

    @include portraitTablet {
      padding: 40px 0;
    }

    @include largeMobile {
      padding: 30px 0;
    }

    .downloadPage__productFilterContainer {
      width: 100%;
      display: grid;
      grid-template-columns: 0.3fr 1fr;
      gap: 30px;

      .downloadPage__pfLeft {
        width: 100%;

        .downloadPage__filterContainer {
          display: flex;
          flex-direction: column;
          gap: 20px;

          .dpf__header {
            width: 100%;
            display: flex;
            padding-bottom: 5px;
            border-bottom: 1px solid #000000;

            span {
              font-size: 22px;
              letter-spacing: 1px;
              font-weight: 700;
              color: #000000;
            }
          }

          .dpf__filterSection {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .dpf__filterSectionHeader {
              span {
                font-size: 16px;
                font-weight: 600;
                color: #000000;
              }
            }

            .dpf__filterSectionBody {
              display: flex;
              flex-direction: column;
              gap: 10px;

              .dpf__filterOption {
                display: flex;
                align-items: center;
                gap: 8px;
              }
            }
          }
        }
      }

      .downloadPage__pfRight {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        row-gap: 20px;
        column-gap: 20px;

        .downloadPage__pfRightItem {
          position: relative;
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;

          gap: 10px;
          background-color: white;
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

          .dsdsd {
            position: absolute;
            top: 10px;
            left: 10px;

            background-color: rgba(141, 141, 141, 0.158);

            .s {
              font-size: 25px;
              color: #c20303;
            }
          }

          img {
            width: 100%;
            height: 215.34px;
            object-fit: contain;
          }

          .name {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 0.2fr;

            gap: 10px;
            padding: 10px;

            span {
              font-size: 14px;
              font-weight: 500;
              color: #000000;
            }

            .s {
              font-size: 25px;
              font-weight: 500;
              color: #c20303;
            }
          }
        }
      }
    }
  }
}
