@import "../../../styles//foundations/mixins.scss";

.workWithUsFormPage {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .wwufp__container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    padding: 64px 0;

    @include tablet {
      padding: 30px 0 40px 0;
    }

    @include largeMobile {
      padding: 20px 0 40px 0;
    }

    .wwufp__header {
      width: 100%;

      border-bottom: 1px solid #222121;

      span {
        font-size: 48px;
        font-style: normal;
        font-weight: 700;
        margin-bottom: 5px;

        @include tablet {
          font-size: 32px;
        }

        @include largeMobile {
          font-size: 24px;
        }
      }
    }

    .wwufp__formContainer {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 30px;

      padding: 50px 0 30px 0;

      @include tablet {
        grid-template-columns: 1fr;
        gap: 15px;
        padding: 30px 0 30px 0;
      }

      .wwufp__twoItemForm {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;

        @include miniPortraitTablet {
          grid-template-columns: 1fr;
          gap: 20px;
        }

        input {
          width: 100%;
          height: 40px;
          padding: 0 10px 0 0;
          box-sizing: border-box;
          border-bottom: 1px solid #b8b8b8;
          outline: none;
          font-size: 16px;
          font-weight: 400;
          color: #222121;
        }
      }
    }

    .wwufp__form2 {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 30px;

      .wwufp__form2Devider {
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-content: space-between;
        gap: 30px;
      }
    }

    .wwufp__socialProfile {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 30px;

      margin-top: 30px;

      .wwufp__socialProfileHeader {
        width: 100%;
        display: flex;
        padding: 5px;
        border-bottom: 1px solid #222121;

        span {
          font-size: 32px;
          font-style: normal;
          font-weight: 700;
          line-height: 45px;

          @include tablet {
            font-size: 24px;
            line-height: 30px;
          }

          @include largeMobile {
            font-size: 18px;
            line-height: 24px;
          }
        }
      }

      p {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 19px;
      }

      .wwufp__fileUploadBtn {
        width: max-content;
        height: 100%;
        padding: 18px 70px;
        outline: none;
        font-size: 16px;
        font-weight: 400;
        color: white;
        background: #2b2b2b;
        display: flex;
        align-items: center;
        justify-content: center;

        @include tablet {
          padding: 14px 50px;
        }

        @include largeMobile {
          padding: 12px 50px;
        }

        input[type="file"] {
          display: none;
        }
      }
    }

    .openPositionBottom__BtnContainer {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      margin-top: 30px;
      padding-top: 20px;
      border-top: 1px solid #222121;

      @include tablet {
        flex-direction: column;
        align-items: start;
        gap: 20px;
      }

      .openPositionBottom__BtnContainer__left {
        display: flex;
        align-items: center;
        gap: 20px;

        @include tablet {
          flex-direction: column;
          align-items: start;
          gap: 20px;
        }

        .positionDetails__applyBtn {
          width: 180px;
          height: 60px;
          background-color: #2b2b2b;
          color: #ffffff;
          border: none;
          outline: none;
          cursor: pointer;
          font-weight: 700;
          font-size: 22px;
          line-height: 18px;

          @include tablet {
            width: 150px;
            height: 50px;
            font-size: 18px;
            line-height: 14px;
          }

          @include largeMobile {
            width: 120px;
            height: 40px;
            font-size: 16px;
            line-height: 12px;
          }
        }

        .positionDetails__prevApply {
          width: 294px;
          height: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 10px;
          border: 1px solid #2b2b2b;
          transition: all 0.3s ease-in-out;

          @include tablet {
            width: 250px;
            height: 50px;
          }

          @include largeMobile {
            width: 100%;
            height: 100%;
            padding: 8px 20px;
          }

          img {
            width: 40px;
            height: 16px;

            @include largeMobile {
              width: 30px;
              height: 12px;
            }
          }

          span {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
          }

          &:hover {
            border: 1px solid orange;
            transition: all 0.3s ease-in-out;
          }
        }
      }

      .openPositionBottom__BtnContainer__right {
        display: flex;
        align-items: center;
        gap: 20px;

        @include largeMobile {
          gap: 10px;
        }

        span {
          font-weight: 700;
          font-size: 32px;
          line-height: 32px;

          @include tablet {
            font-size: 24px;
            line-height: 24px;
          }

          @include largeMobile {
            font-size: 18px;
            line-height: 18px;
          }
        }

        i {
          font-size: 22px;
          line-height: 32px;
          cursor: pointer;

          @include tablet {
            font-size: 18px;
            line-height: 24px;
          }

          @include largeMobile {
            font-size: 16px;
            line-height: 18px;
          }
        }
      }
    }
  }
}

.wwufp__formInput {
  width: 100%;
  height: 40px;
  padding: 0 10px 0 0;
  box-sizing: border-box;
  border-bottom: 1px solid #b8b8b8;
  outline: none;
  font-size: 16px;
  font-weight: 400;
  color: #222121;
}

.wwufp__formInputSelect {
  width: 100%;
  height: 40px;
  padding: 0 10px 0 0;
  box-sizing: border-box;
  border-bottom: 1px solid #b8b8b8;
  outline: none;
  font-size: 16px;
  font-weight: 400;
  color: #222121;
}

// .wwufp__form2Checkbox {
//   display: flex;
//   align-items: center;
//   gap: 10px;

//   input {
//     width: 15px;
//     height: 15px;
//   }
// }

.wwufp__form2Checkbox {
  display: flex;
  align-items: flex-start;
  gap: 10px;

  .wwufp__Checkbox {
    width: 15px;
    height: 15px;
    margin-top: 5px;
  }
}

.wwufp__textarea {
  width: 100%;
  height: 250px;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #b8b8b8;
  outline: none;
  font-size: 16px;
  font-weight: 400;
  color: #222121;
  resize: none;
}
