@import "../../../styles/foundations/mixins.scss";

.referenceProjectList {
  display: flex;
  flex-direction: column;

  .referenceProjectList__container {
    width: 100%;
    display: flex;
    flex-direction: column;

    padding: 64px 0;
    box-sizing: border-box;

    @include tablet {
      padding: 30px 0 40px 0;
      transition: all 0.5s ease-in-out;
    }

    @include largeMobile {
      padding: 20px 0 40px 0;
    }

    .referenceProjectList__block {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 40px;
      margin-bottom: 40px;

      @include wrapper1 {
        gap: 20px;
        margin-bottom: 20px;
      }

      @include tablet {
        grid-template-columns: 1fr 1fr;
      }

      @include miniPortraitTablet {
        grid-template-columns: 1fr;
      }

      .referenceProjectList__card {
        position: relative;
        width: 100%;
        height: 450px;
        height: 100%;
        display: flex;
        background-color: var(--bg__black);

        @include tablet {
          height: 100%;
        }

        .referenceProjectList__cardRow__image {
          width: 100%;
          height: 450px;
          display: flex;

          @include tablet {
            height: 400px;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            opacity: 65%;
          }
        }

        .referenceProjectList__cardRow__content {
          height: 450px;
          display: flex;
        }
      }

      .referenceProjectList__card:nth-child(1) {
        grid-column-start: span 2;

        @include tablet {
          grid-column-start: span 1;
        }

        .referenceProjectList__cardRow__content {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          // height: 100px;
          height: max-content;
          display: flex;
          align-items: flex-end;
          justify-content: space-between;

          padding: 0 32px 32px 32px;
          box-sizing: border-box;

          @include tablet {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: flex-start;

            flex-direction: column;
            justify-content: space-between;

            padding: 20px;
            box-sizing: border-box;
          }

          .referenceProjectList__cardRow__contentLeft {
            width: 100%;
            // width: max-content;
            height: max-content;
            display: flex;
            flex-direction: column;
            gap: 5px;
            padding: 10px;
            box-sizing: border-box;
            background-color: #ffffff;
            opacity: 60%;

            .referenceProjectList__cardRow__contentLeft__title {
              font-weight: 700;
              font-size: 24px;
              line-height: 24px;

              @include wrapper1 {
                font-weight: 700;
                font-size: 20px;
                line-height: 20px;
              }

              @include largeMobile {
                font-weight: 700;
                font-size: 16px;
                line-height: 16px;
              }
            }

            .referenceProjectList__cardRow__contentLeft__Location {
              display: flex;
              align-items: center;
              gap: 5px;
            }
          }

          .referenceProjectList__cardRow__contentRight {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;

            .referenceProjectList__cardRow__contentRightLink {
              // height: 24px;
              display: flex;
              align-items: center;
              gap: 10px;

              span {
                font-weight: 700;
                font-size: 24px;
                line-height: 24px;
                color: white;
                padding-bottom: 5px;

                @include wrapper1 {
                  font-weight: 700;
                  font-size: 20px;
                  line-height: 20px;
                }

                @include largeMobile {
                  font-weight: 700;
                  font-size: 16px;
                  line-height: 16px;
                }
              }

              img {
                width: 100%;
                height: 30px;
                object-fit: cover;

                @include wrapper1 {
                  width: 100%;
                  height: 20px;
                  object-fit: cover;
                }

                @include largeMobile {
                  width: 100%;
                  height: 16px;
                  object-fit: cover;
                }
              }
            }
          }
        }
      }

      .referenceProjectList__card:nth-child(2)
        .referenceProjectList__cardRow__content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        padding: 32px;
        box-sizing: border-box;

        @include tablet {
          padding: 20px;
        }

        .referenceProjectList__cardRow__contentLeft {
          width: max-content;
          display: flex;
          flex-direction: column;
          gap: 5px;
          padding: 10px;
          box-sizing: border-box;
          background-color: #ffffff;
          opacity: 60%;

          @include wrapper1 {
            width: 100%;
          }

          @include largeMobile {
            width: 100%;
          }

          .referenceProjectList__cardRow__contentLeft__title {
            text-align: justify;
            font-weight: 700;
            font-size: 24px;
            line-height: 24px;

            @include wrapper1 {
              font-weight: 700;
              font-size: 20px;
              line-height: 20px;
            }

            @include largeMobile {
              font-weight: 700;
              font-size: 16px;
              line-height: 16px;
            }
          }

          .referenceProjectList__cardRow__contentLeft__Location {
            display: flex;
            align-items: center;
            gap: 5px;
            text-align: justify;
          }
        }

        .referenceProjectList__cardRow__contentRight {
          height: 100%;
          display: flex;
          align-items: flex-end;
          justify-content: flex-end;

          .referenceProjectList__cardRow__contentRightLink {
            // height: 24px;
            display: flex;
            align-items: center;
            gap: 10px;

            span {
              font-weight: 700;
              font-size: 24px;
              line-height: 24px;
              color: white;
              padding-bottom: 5px;

              @include wrapper1 {
                font-weight: 700;
                font-size: 20px;
                line-height: 20px;
              }

              @include largeMobile {
                font-weight: 700;
                font-size: 16px;
                line-height: 16px;
              }
            }

            img {
              width: 100%;
              height: 30px;
              object-fit: cover;

              @include wrapper1 {
                width: 100%;
                height: 20px;
                object-fit: cover;
              }

              @include largeMobile {
                width: 100%;
                height: 16px;
                object-fit: cover;
              }
            }
          }
        }
      }

      .referenceProjectList__card:nth-child(3) {
        grid-column-start: span 3;

        @include tablet {
          grid-column-start: span 2;
        }

        @include miniPortraitTablet {
          grid-column-start: span 1;
        }

        .referenceProjectList__cardRow__content {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          // height: 100px;
          height: max-content;
          display: flex;
          align-items: flex-end;
          justify-content: space-between;

          padding: 0 32px 32px 32px;
          box-sizing: border-box;

          @include tablet {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: flex-start;

            flex-direction: column;
            justify-content: space-between;

            padding: 20px;
            box-sizing: border-box;
          }

          .referenceProjectList__cardRow__contentLeft {
            width: 100%;
            // width: max-content;
            height: max-content;
            display: flex;
            flex-direction: column;
            gap: 5px;
            padding: 10px;
            box-sizing: border-box;
            background-color: #ffffff;
            opacity: 60%;

            .referenceProjectList__cardRow__contentLeft__title {
              font-weight: 700;
              font-size: 24px;
              line-height: 24px;

              @include wrapper1 {
                font-weight: 700;
                font-size: 20px;
                line-height: 20px;
              }

              @include largeMobile {
                font-weight: 700;
                font-size: 16px;
                line-height: 16px;
              }
            }

            .referenceProjectList__cardRow__contentLeft__Location {
              display: flex;
              align-items: center;
              gap: 5px;
            }
          }

          .referenceProjectList__cardRow__contentRight {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;

            .referenceProjectList__cardRow__contentRightLink {
              // height: 24px;
              display: flex;
              align-items: center;
              gap: 10px;

              span {
                font-weight: 700;
                font-size: 24px;
                line-height: 24px;
                color: white;
                padding-bottom: 5px;

                @include wrapper1 {
                  font-weight: 700;
                  font-size: 20px;
                  line-height: 20px;
                }

                @include largeMobile {
                  font-weight: 700;
                  font-size: 16px;
                  line-height: 16px;
                }
              }

              img {
                width: 100%;
                height: 30px;
                object-fit: cover;

                @include wrapper1 {
                  width: 100%;
                  height: 20px;
                  object-fit: cover;
                }

                @include largeMobile {
                  width: 100%;
                  height: 16px;
                  object-fit: cover;
                }
              }
            }
          }
        }
      }

      .referenceProjectList__card:nth-child(4)
        .referenceProjectList__cardRow__content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        padding: 32px;
        box-sizing: border-box;

        @include tablet {
          padding: 20px;
        }

        .referenceProjectList__cardRow__contentLeft {
          width: max-content;
          display: flex;
          flex-direction: column;
          gap: 5px;
          padding: 10px;
          box-sizing: border-box;
          background-color: #ffffff;
          opacity: 60%;

          @include wrapper1 {
            width: 100%;
          }

          @include largeMobile {
            width: 100%;
          }

          .referenceProjectList__cardRow__contentLeft__title {
            text-align: justify;
            font-weight: 700;
            font-size: 24px;
            line-height: 24px;

            @include wrapper1 {
              font-weight: 700;
              font-size: 20px;
              line-height: 20px;
            }

            @include largeMobile {
              font-weight: 700;
              font-size: 16px;
              line-height: 16px;
            }
          }

          .referenceProjectList__cardRow__contentLeft__Location {
            display: flex;
            align-items: center;
            gap: 5px;
            text-align: justify;
          }
        }

        .referenceProjectList__cardRow__contentRight {
          height: 100%;
          display: flex;
          align-items: flex-end;
          justify-content: flex-end;

          .referenceProjectList__cardRow__contentRightLink {
            // height: 24px;
            display: flex;
            align-items: center;
            gap: 10px;

            span {
              font-weight: 700;
              font-size: 24px;
              line-height: 24px;
              color: white;
              padding-bottom: 5px;

              @include wrapper1 {
                font-weight: 700;
                font-size: 20px;
                line-height: 20px;
              }

              @include largeMobile {
                font-weight: 700;
                font-size: 16px;
                line-height: 16px;
              }
            }

            img {
              width: 100%;
              height: 30px;
              object-fit: cover;

              @include wrapper1 {
                width: 100%;
                height: 20px;
                object-fit: cover;
              }

              @include largeMobile {
                width: 100%;
                height: 16px;
                object-fit: cover;
              }
            }
          }
        }
      }

      .referenceProjectList__card:nth-child(5) {
        grid-column-start: span 2;

        @include tablet {
          grid-column-start: span 1;
        }

        .referenceProjectList__cardRow__content {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          // height: 100px;
          height: max-content;
          display: flex;
          align-items: flex-end;
          justify-content: space-between;

          padding: 0 32px 32px 32px;
          box-sizing: border-box;

          @include tablet {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: flex-start;

            flex-direction: column;
            justify-content: space-between;

            padding: 20px;
            box-sizing: border-box;
          }

          .referenceProjectList__cardRow__contentLeft {
            width: 100%;
            // width: max-content;
            height: max-content;
            display: flex;
            flex-direction: column;
            gap: 5px;
            padding: 10px;
            box-sizing: border-box;
            background-color: #ffffff;
            opacity: 60%;

            .referenceProjectList__cardRow__contentLeft__title {
              font-weight: 700;
              font-size: 24px;
              line-height: 24px;

              @include wrapper1 {
                font-weight: 700;
                font-size: 20px;
                line-height: 20px;
              }

              @include largeMobile {
                font-weight: 700;
                font-size: 16px;
                line-height: 16px;
              }
            }

            .referenceProjectList__cardRow__contentLeft__Location {
              display: flex;
              align-items: center;
              gap: 5px;
            }
          }

          .referenceProjectList__cardRow__contentRight {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: flex-end;
            justify-content: flex-end;

            .referenceProjectList__cardRow__contentRightLink {
              // height: 24px;
              display: flex;
              align-items: center;
              gap: 10px;

              span {
                font-weight: 700;
                font-size: 24px;
                line-height: 24px;
                color: white;
                padding-bottom: 5px;

                @include wrapper1 {
                  font-weight: 700;
                  font-size: 20px;
                  line-height: 20px;
                }

                @include largeMobile {
                  font-weight: 700;
                  font-size: 16px;
                  line-height: 16px;
                }
              }

              img {
                width: 100%;
                height: 30px;
                object-fit: cover;

                @include wrapper1 {
                  width: 100%;
                  height: 20px;
                  object-fit: cover;
                }

                @include largeMobile {
                  width: 100%;
                  height: 16px;
                  object-fit: cover;
                }
              }
            }
          }
        }
      }
    }
  }
}
