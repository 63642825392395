@import "../../../styles/foundations/mixins.scss";

.productDetailsPage {
  display: flex;
  flex-direction: column;

  .productDetailsContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 64px 0;
    box-sizing: border-box;

    @include tablet {
      padding: 30px 0 40px 0;
      transition: all 0.5s ease-in-out;
    }

    @include largeMobile {
      padding: 20px 0 40px 0;
    }

    .productDetailsContainer__block1 {
      // width: 1620px;
      width: 100%;
      display: flex;
      flex-direction: column;

      gap: 20px;

      // @media screen and (max-width: 1650px) {
      //   width: 100%;
      //   padding: 0 20px;
      // }

      .productDetailsContainerTop {
        display: flex;
        flex-direction: column;
        gap: 10px;

        h1 {
          font-family: "oswald";
          font-weight: 700;
          font-size: 32px;
          line-height: 24px;

          @include tablet {
            font-size: 24px;
          }

          @include largeMobile {
            font-size: 20px;
          }
        }

        p {
          font-family: "oswald";
          font-weight: 400;
          font-size: 24px;
          line-height: 36px;

          @include tablet {
            font-size: 20px;
          }

          @include largeMobile {
            font-size: 16px;
          }
        }
      }

      .productDetailsContainerBottom {
        width: 100%;
        display: grid;
        grid-template-columns: 0.4fr 1fr;
        gap: 30px;

        @include mediumDesktop {
          grid-template-columns: 1fr;
          gap: 20px;
        }

        .prodLeft {
          display: flex;
          align-items: center;
          justify-content: center;
          // height: 440px;
          // width: 425px;
          // border: 5px solid #ffd96f;

          height: 360px;
          width: 350px;

          @include miniPortraitTablet {
            width: 100%;
            height: 300px;
          }

          .prodImg {
            // width: 234px;
            // height: 308px;
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            @include miniPortraitTablet {
              width: 100%;
              height: 100%;
            }

            img {
              width: 100%;
              height: 100%;
              // object-fit: contain;
              object-fit: cover;
            }
          }
        }

        .prodRight {
          display: flex;
          flex-direction: column;
          gap: 20px;

          .prodRightTop__btns {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
            row-gap: 5px;
            border-bottom: 1px solid rgb(129, 129, 129);

            .prodRightTop__btn {
              display: flex;
              align-items: center;
              justify-content: center;

              padding: 10px 5px;
              cursor: pointer;
              transition: all 0.3s ease-in-out;

              &.active {
                background-color: rgba(0, 0, 0, 0.856);
                transition: all 0.3s ease-in-out;

                .prodRightTop__text {
                  span {
                    color: white;
                    font-weight: 700;
                    transition: all 0.3s ease-in-out;
                  }
                }
              }

              &:hover {
                background-color: rgba(0, 0, 0, 0.856);
                transition: all 0.3s ease-in-out;

                .prodRightTop__text {
                  span {
                    color: white;
                    font-weight: 700;
                    transition: all 0.3s ease-in-out;
                  }
                }
              }

              .prodRightTop__text {
                span {
                  font-family: "Inter";
                  font-size: 15px;
                  font-weight: 500;
                  transition: all 0.3s ease-in-out;
                }
              }
            }
          }

          .prodRightBottom {
            .prodRightBottomDesc {
              display: flex;
              flex-direction: column;
              gap: 20px;

              .aditional {
                width: 100%;
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 10px;

                .ad__list {
                  display: flex;
                  flex-direction: column;
                  gap: 5px;

                  span {
                    font-size: 15px;
                    font-weight: 700;
                  }

                  ul {
                    list-style: inside;

                    padding-left: 5px;

                    li {
                    }
                  }
                }
              }
            }

            .prodRightBottomDescItem {
              width: 100%;
              display: grid;
              grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
              gap: 30px;

              @include largeMobile {
                gap: 15px;
                transition: all 0.5s ease-in-out;
              }

              .prodRightBottomDescItemLink {
                display: flex;
                align-items: center;
                gap: 20px;
                padding: 15px;

                border-radius: 10px;
                box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
                  rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
                transition: all 0.5s ease-in-out;

                &:hover {
                  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
                    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px,
                    rgba(209, 209, 209, 0.5) 0px 0px 0px 3px;
                  transition: all 0.5s ease-in-out;
                }

                .prodRightBottomDescItem__icon {
                  font-size: 24px;
                }

                .prodRightBottomDescItem__text {
                  font-size: 18px;
                  font-weight: 600;
                }
              }
            }
          }
        }
      }
    }
  }

  .productPage__relatedProductApplication {
    width: 100%;
    display: flex;
    flex-direction: column;

    padding-bottom: 50px;

    .relatedProductApplication__Header {
      display: flex;
      margin-bottom: 30px;

      .relatedProductApplication__HeaderText {
        font-weight: 400;
        font-size: 22px;
        font-family: "oswald";

        @include largeMobile {
          font-size: 18px;
        }
      }
    }

    .relatedProductApplication__container {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
      gap: 30px;

      @include miniPortraitTablet {
        width: 100%;
        grid-template-columns: 1fr;
      }

      .SegmentBottomBox {
        width: 100%;
        position: relative;
        display: flex;

        img {
          width: 100%;
          height: 100%;
        }

        .SegmentBottomBoxSpan {
          position: absolute;
          bottom: 15px;
          left: 15px;

          padding: 8px;
          box-sizing: border-box;

          background-color: rgb(189, 189, 189);
          opacity: 0.6;

          @include miniPortraitTablet {
            width: 80%;
            height: max-content;
            font-size: 14px;
            padding: 5px;
          }
        }
      }
    }

    .productPage__relatedProductColaps {
      margin-bottom: 50px;
      display: flex;
      flex-direction: column;

      @include miniPortraitTablet {
        margin-bottom: 40px;
      }
    }
  }
}

@keyframes fadeIn {
  // left to right and make it smooth
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
