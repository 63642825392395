@import "../../../styles/foundations/mixins.scss";

.referenceProjectDetails {
  display: flex;
  flex-direction: column;

  .referenceProjectDetails__container {
    width: 100%;
    display: flex;
    flex-direction: column;

    padding: 64px 0;
    box-sizing: border-box;

    @include tablet {
      padding: 30px 0 40px 0;
      transition: all 0.5s ease-in-out;
    }

    @include largeMobile {
      padding: 20px 0 40px 0;
    }

    .referenceProjectDetails__ImageAndHeader {
      display: flex;
      flex-direction: column;

      gap: 48px;

      @include tablet {
        gap: 30px;
        transition: 0.5s ease-in-out;
      }

      @include largeMobile {
        gap: 20px;
        transition: 0.5s ease-in-out;
      }

      .referenceProjectDetails__Image {
        width: 100%;
        height: 550px;
        background-color: var(--black);
        transition: 0.5s ease-in-out;

        @include tablet {
          height: 400px;
          transition: 0.5s ease-in-out;
        }

        @include largeMobile {
          height: 300px;
          transition: 0.5s ease-in-out;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          opacity: 85%;
        }
      }

      .referenceProjectDetails__Header {
        display: flex;
        flex-direction: column;
        gap: 16px;

        @include tablet {
          gap: 8px;
          transition: 0.5s ease-in-out;
        }

        span {
          font-weight: 700;
          font-size: 32px;
          line-height: 40px;

          @include tablet {
            font-size: 24px;
            line-height: 32px;
          }

          @include largeMobile {
            font-size: 20px;
            line-height: 24px;
          }
        }

        .referenceProjectDetails__HeaderUnderline {
          width: 100px;
          height: 1px;
          background-color: var(--border__black);
        }
      }
    }

    .referenceProjectDetails__projectProfile__Container {
      display: flex;
      flex-direction: column;

      padding: 104px 0 72px 0;
      box-sizing: border-box;
      border-bottom: 1px solid var(--border__whiteGray);

      @include tablet {
        padding: 50px 0 30px 0;
        transition: 0.5s ease-in-out;
      }

      @include largeMobile {
        padding: 30px 0 20px 0;
        transition: 0.5s ease-in-out;
      }

      .referenceProjectDetails__projectProfile__Header {
        display: flex;
        flex-direction: column;
        gap: 16px;

        @include tablet {
          gap: 8px;
          transition: 0.5s ease-in-out;
        }

        span {
          font-weight: 700;
          font-size: 56px;
          line-height: 68px;

          @include tablet {
            font-size: 32px;
            line-height: 40px;
          }

          @include largeMobile {
            font-size: 24px;
            line-height: 32px;
          }
        }

        .referenceProjectDetails__projectProfile__HeaderUnderline {
          width: 80px;
          height: 1px;
          background-color: var(--border__black);
        }
      }

      .projectProfile__contentContainer {
        display: grid;
        grid-template-columns: repeat(3, 1fr);

        gap: 30px;
        padding: 50px 0;
        box-sizing: border-box;

        @include tablet {
          grid-template-columns: repeat(2, 1fr);
          padding: 30px 0;
          gap: 20px;
          transition: 0.5s ease-in-out;
        }

        @include largeMobile {
          grid-template-columns: 1fr;
          padding: 20px 0;
          gap: 20px;
          transition: 0.5s ease-in-out;
        }

        .projectProfile__content {
          display: flex;
          flex-direction: column;
          //   gap: 8px;

          .projectProfile__contentHeader {
            span {
              font-weight: 700;
              font-size: 24px;
              line-height: 32px;

              @include largeMobile {
                font-size: 18px;
                line-height: 24px;
              }
            }
          }

          .projectProfile__contentDesc {
            span {
              font-weight: 400;
              font-size: 16px;
              line-height: 32px;

              @include largeMobile {
                font-size: 14px;
                line-height: 20px;
              }
            }
          }
        }
      }

      .projectProfile__projectLocation {
        display: flex;
        flex-direction: column;

        .projectProfile__projectLocationHeader {
          span {
            font-weight: 700;
            font-size: 32px;
            line-height: 32px;

            @include largeMobile {
              font-size: 24px;
              line-height: 32px;
            }

            @include mediumMobile {
              font-size: 20px;
              line-height: 24px;
            }
          }
        }

        .projectProfile__projectLocationText {
          span {
            font-weight: 400;
            font-size: 16px;
            line-height: 32px;

            @include largeMobile {
              font-size: 14px;
              line-height: 20px;
            }
          }
        }
      }
    }

    .projectProfile__videoContainer {
      display: flex;
      flex-direction: column;

      padding: 66px 0;
      box-sizing: border-box;

      .projectProfile__video {
        width: 100%;
        height: 658px;

        iframe {
          width: 100%;
          height: 100%;

          border-radius: 45px;
        }
      }
    }

    .projectProfile__projectUsedProduct__container {
      display: flex;
      flex-direction: column;

      padding: 52px 0;
      box-sizing: border-box;

      @include tablet {
        padding: 30px 0;
        transition: 0.5s ease-in-out;
      }

      @include largeMobile {
        padding: 20px 0;
        transition: 0.5s ease-in-out;
      }

      .projectProfile__projectUsedProduct__Header {
        display: flex;
        flex-direction: column;
        gap: 16px;

        span {
          font-weight: 700;
          font-size: 56px;
          line-height: 68px;

          @include tablet {
            font-size: 32px;
            line-height: 40px;
          }

          @include largeMobile {
            font-size: 24px;
            line-height: 32px;
          }
        }

        .projectProfile__projectUsedProduct__HeaderUnderline {
          width: 100px;
          height: 1px;
          background-color: var(--border__black);
        }
      }

      .projectProfile__projectUsedProduct__contentContainer {
        display: grid;
        grid-template-columns: repeat(3, 1fr);

        gap: 30px;
        padding: 50px 0 0 0;
        box-sizing: border-box;

        @include tablet {
          grid-template-columns: repeat(2, 1fr);
          padding: 30px 0 0 0;
          gap: 20px;
          transition: 0.5s ease-in-out;
        }

        @include largeMobile {
          grid-template-columns: 1fr;
          padding: 20px 0 0 0;
          gap: 20px;
          transition: 0.5s ease-in-out;
        }

        .projectProfile__projectUsedProduct__content {
          display: flex;
          flex-direction: column;
          gap: 5px;

          .projectProfile__projectUsedProduct__contentHeader {
            span {
              font-weight: 700;
              font-size: 24px;
              line-height: 32px;

              @include largeMobile {
                font-size: 18px;
                line-height: 24px;
              }

              @include mediumMobile {
                font-size: 16px;
                line-height: 24px;
              }
            }
          }

          .projectProfile__projectUsedProduct__contentDesc {
            span {
              font-weight: 400;
              font-size: 16px;
              line-height: 22px;

              @include largeMobile {
                font-size: 14px;
                line-height: 20px;
              }

              @include mediumMobile {
                font-size: 12px;
                line-height: 18px;
              }
            }
          }
        }
      }
    }
  }
}
