@import "../../../styles/foundations/mixins.scss";

.dealers {
  width: 100%;
  display: flex;
  flex-direction: column;

  .dealers__container {
    display: flex;
    flex-direction: column;

    padding: 80px 0;
    box-sizing: border-box;

    @include extraLargeTablet {
      padding: 60px 0;
    }

    @include portraitTablet {
      padding: 40px 0;
    }

    @include largeMobile {
      padding: 30px 0;
    }

    .dealer_grid {
      display: grid;
      // grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 30px;

      @include mediumTablet {
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
      }

      .dealer_card {
        position: relative;
        width: 100%;
        padding: 50px;
        height: 570px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 20px;
        background-color: #ececec;
        transition: all 0.3s ease-in-out;
        cursor: pointer;

        @include extraLargeTablet {
          padding: 35px;
        }

        @include portraitTablet {
          padding: 30px;
          height: auto;
        }

        &:before {
          position: absolute;
          left: 0;
          top: 0;
          content: "";
          display: block;
          width: 100%;
          height: 100%;
          background-color: #ffc220;
          transform-origin: 0 bottom 0;
          transform: scaleX(0);
          transition: 0.4s ease-out;
        }

        //on hover color changes to white with animation
        &:hover {
          background-color: #ffc220;
          scale: 1.05;

          &:before {
            transform: scaleX(1);
          }
        }

        .dealer_img {
          position: relative;
          height: 100px;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;

            //image top align
            object-position: top;
          }
        }

        .dealer_content {
          position: relative;
          display: flex;
          margin-top: 30px;
          text-align: justify;

          @include portraitTablet {
            margin-top: 20px;
          }

          @include largeMobile {
            margin-top: 20px;
          }

          h3 {
            font-size: 30px;
            font-weight: 600;
          }

          p {
            font-size: 20px;
            font-weight: 400;
            margin-top: 10px;
          }

          hr {
            margin-top: 20px;
            border: none;
          }
        }

        .dealer_discover_btn {
          position: relative;
          width: 100%;
          display: flex;
        }
      }
    }
  }
}
