@import "../../styles/foundations/mixins.scss";

#ContactUs {
  select {
    color: #9ca3b5;
    background-color: #9ca3b5;
  }
}

.contactUs {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .contactUs__container {
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;

    gap: 70px;
    padding: 64px 0;
    box-sizing: border-box;

    @include largeMobile {
      gap: 40px;
    }

    .contactUs__mapAnddetails {
      display: grid;
      grid-template-columns: 1fr 0.7fr;
      align-items: center;

      gap: 70px;

      @include smallDesktop {
        grid-template-columns: 1fr;
        gap: 30px;

        .contactUs__map {
          order: 2;
        }
      }

      .contactUs__map {
        width: 100%;
        height: 600px;

        padding: 15px;
        box-sizing: border-box;
        border-radius: 5px;
        background-color: var(--bg__white__shade3);
        transition: 0.5s ease-in-out;

        @include miniPortraitTablet {
          height: 400px;
          padding: 10px;

          transition: 0.5s ease-in-out;
        }
      }

      .contactUs__details {
        display: flex;
        flex-direction: column;

        gap: 20px;

        .contactUs__detailsContainer__headerAndDetails {
          display: flex;
          flex-direction: column;

          gap: 5px;

          .contactUs__detailsContainer__headerText {
            font-weight: 800;
            font-size: 22px;
            line-height: 24px;

            @include miniPortraitTablet {
              font-size: 20px;
              line-height: 22px;
            }

            @include largeMobile {
              font-size: 18px;
              line-height: 20px;
            }
          }

          .contactUs__detailsContainer__detailsText {
            font-weight: 400;
            font-size: 20px;
            line-height: 24px;

            @include miniPortraitTablet {
              font-size: 18px;
              line-height: 22px;
            }

            @include largeMobile {
              font-size: 16px;
              line-height: 20px;
            }
          }
        }

        .contactUs__detailsContainerBtn {
          width: max-content;
          display: flex;
          align-items: center;
          gap: 10px;
          background-color: var(--bg__yellow);

          padding: 12px 20px;
          box-sizing: border-box;
          border-radius: 5px;
          text-align: center;
          text-transform: uppercase;
          font-weight: 400;
          font-size: 22px;
          cursor: pointer;

          @include miniPortraitTablet {
            font-size: 20px;
            padding: 10px 15px;
          }

          @include largeMobile {
            width: 90%;
            font-size: 18px;
            padding: 8px 12px;
          }
        }
      }
    }

    .contactUs__branchesContainer {
      width: 100%;
      display: flex;
      flex-direction: column;

      gap: 40px;

      .contactUs__branchesContainer__header {
        width: 100%;
        display: flex;
        flex-direction: column;

        padding-bottom: 15px;
        border-bottom: 1.5px solid var(--black);

        .contactUs__branchesContainer__headerText {
          font-weight: 800;
          font-size: 62px;
          line-height: 52px;
          transition: 0.5s ease-in-out;

          @include miniPortraitTablet {
            font-size: 50px;
            line-height: 42px;
            transition: 0.5s ease-in-out;
          }

          @include largeMobile {
            font-size: 35px;
            line-height: 32px;
            transition: 0.5s ease-in-out;
          }
        }
      }

      .contactUs__branchesList {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 30px;

        @include largeTablet {
          grid-template-columns: repeat(2, 1fr);
        }

        @include largeMobile {
          grid-template-columns: 1fr;
        }

        .contactUs__branchesList__item {
          display: flex;
          flex-direction: column;

          gap: 20px;

          .contactUs__branchesList__itemContainer {
            display: flex;
            flex-direction: column;

            gap: 10px;

            .contactUs__branchesList__item__header {
              font-weight: 800;
              font-size: 22px;
              line-height: 24px;

              @include miniPortraitTablet {
                font-size: 20px;
                line-height: 22px;
              }

              @include largeMobile {
                font-size: 18px;
                line-height: 20px;
              }
            }

            .contactUs__branchesList__item__details {
              font-weight: 400;
              font-size: 20px;
              line-height: 24px;

              @include miniPortraitTablet {
                font-size: 18px;
                line-height: 22px;
              }

              @include largeMobile {
                font-size: 16px;
                line-height: 20px;
              }
            }
          }
        }
      }
    }

    .contactUs__contactAndForm {
      display: flex;
      flex-direction: column;

      gap: 40px;

      @include largeMobile {
        gap: 20px;
      }

      .contactUs__contactHeader {
        display: flex;
        flex-direction: column;

        .contactUs__contactHeader__text {
          width: max-content;
          font-weight: 800;
          font-size: 62px;
          line-height: 52px;
          transition: 0.5s ease-in-out;

          padding-bottom: 15px;
          border-bottom: 1.5px solid var(--black);

          @include miniPortraitTablet {
            font-size: 50px;
            line-height: 42px;
            transition: 0.5s ease-in-out;
          }

          @include largeMobile {
            font-size: 35px;
            line-height: 32px;
            transition: 0.5s ease-in-out;
          }
        }
      }

      .contactUs__contactDesc {
        display: flex;
        flex-direction: column;

        .contactUs__contactUs__contactDesc__text {
          font-weight: 400;
          font-size: 20px;
          line-height: 24px;
          text-align: justify;

          @include miniPortraitTablet {
            font-size: 18px;
            line-height: 22px;
          }

          @include largeMobile {
            font-size: 16px;
            line-height: 20px;
          }
        }
      }

      .contactUs__contactForm {
        display: flex;
        flex-direction: column;

        gap: 30px;

        @include largeMobile {
          gap: 10px;
        }

        .contactUs__threeM__inputContainer {
          display: grid;
          grid-template-columns: 0.5fr 1fr 0.5fr;

          gap: 30px;

          @include smallDesktop {
            grid-template-columns: 1fr;
            gap: 20px;
          }

          @include largeMobile {
            grid-template-columns: 1fr;
            gap: 10px;
          }
        }

        .contactUs__threeE__inputContainer {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;

          gap: 30px;

          @include smallDesktop {
            grid-template-columns: 1fr;
            gap: 20px;
          }

          @include largeMobile {
            grid-template-columns: 1fr;
            gap: 10px;
          }
        }

        .contactUs__threeL__inputContainer {
          display: grid;
          grid-template-columns: 0.5fr 0.5fr 1fr;

          gap: 30px;

          @include smallDesktop {
            grid-template-columns: 1fr;
            gap: 20px;
          }

          @include largeMobile {
            grid-template-columns: 1fr;
            gap: 10px;
          }
        }

        .contactUs__two__inputContainer {
          display: grid;
          grid-template-columns: 1fr 1fr;

          gap: 30px;

          @include smallDesktop {
            grid-template-columns: 1fr;
            gap: 20px;
          }

          @include largeMobile {
            grid-template-columns: 1fr;
            gap: 10px;
          }
        }

        .contactUs__messageBox {
          textarea {
            width: 100%;
            height: 100%;
            padding: 15px 15px;
            box-sizing: border-box;
            border: 2px solid var(--bg__input);
            background-color: var(--bg__input);
            color: var(--text__black);
            font-weight: 400;
            font-size: 20px;
            line-height: 24px;
            transition: 0.5s ease-in-out;
            resize: none;

            &:focus {
              outline: none;
              border: 2px solid var(--bg__yellow);
            }

            @include miniPortraitTablet {
              font-size: 18px;
              line-height: 22px;
            }

            @include largeMobile {
              font-size: 16px;
              line-height: 20px;
            }
          }
        }
      }
    }

    .contactUs__btnContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      justify-content: space-between;
      transition: 0.5s ease-in-out;

      margin-bottom: 2rem;

      @include largeTablet {
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        gap: 20px;
        margin-bottom: 20px;

        transition: 0.5s ease-in-out;
      }

      .contactUs__checkBoxContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        gap: 10px;

        .contactUs__checkBoxContainer__headerText {
          font-weight: 800;
          font-size: 22px;
          line-height: 24px;

          @include miniPortraitTablet {
            font-size: 20px;
            line-height: 22px;
          }

          @include largeMobile {
            font-size: 18px;
            line-height: 20px;
          }
        }

        .checkboxContainer {
          display: flex;
          align-items: center;
          gap: 10px;

          .contactUs__checkbox {
            width: 20px;
            height: 20px;
            border: 2px solid var(--bg__input);
            background-color: var(--bg__input);
            border-radius: 5px;
            cursor: pointer;

            &:hover {
              border: 2px solid var(--bg__yellow);
            }

            &:focus {
              outline: none;
              border: 2px solid var(--bg__yellow);
            }
          }

          label {
            display: flex;
            align-items: center;
            gap: 10px;

            font-weight: 400;
            font-size: 20px;
            line-height: 24px;

            @include miniPortraitTablet {
              font-size: 18px;
              line-height: 22px;
            }

            @include largeMobile {
              font-size: 16px;
              line-height: 20px;
            }
          }
        }
      }

      .contactUs__subimtBtn {
        width: 25%;
        // height: 60px;

        @include largeTablet {
          width: 50%;
        }

        @include largeMobile {
          width: 100%;
          // height: 40px;
        }
      }
    }
  }
}

.contactUs__select {
  width: 100%;
  height: 100%;
  padding: 15px 15px;
  box-sizing: border-box;
  // border-radius: 5px;
  border: 2px solid var(--bg__input);
  background-color: var(--bg__input);
  color: var(--text__black);
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  transition: 0.5s ease-in-out;

  &:focus {
    outline: none;
    border: 2px solid var(--bg__yellow);
  }

  @include miniPortraitTablet {
    font-size: 18px;
    line-height: 22px;
    padding: 10px 15px;
  }

  @include largeMobile {
    font-size: 16px;
    line-height: 20px;
    padding: 8px 12px;
  }
}

.contactUs__threeM__input {
  width: 100%;
  height: 100%;
  padding: 15px 15px;
  box-sizing: border-box;
  // border-radius: 5px;
  border: 2px solid var(--bg__input);
  background-color: var(--bg__input);
  color: var(--text__black);
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  transition: 0.5s ease-in-out;

  &:focus {
    outline: none;
    border: 2px solid var(--bg__yellow);
  }

  @include miniPortraitTablet {
    font-size: 18px;
    line-height: 22px;
    padding: 10px 15px;
  }

  @include largeMobile {
    font-size: 16px;
    line-height: 20px;
    padding: 8px 12px;
  }
}
