.targetMarket {
  display: flex;
  flex-direction: column;

  .targetMarket__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 100px;

    padding: 64px 0;
    box-sizing: border-box;

    .targetMarket__block {
      width: 100%;
      height: 100%;
      display: grid;
      grid-template-columns: 1fr 0.6fr;

      gap: 30px;

      .targetMarket__block__Left {
        width: 100%;
        display: flex;
        flex-direction: column;

        gap: 20px;

        .targetMarket__block__LeftImage {
          width: 100%;
          height: 450px;
          background-color: black;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            opacity: 65%;
          }
        }

        .targetMarket__block__LeftDesc {
          width: 100%;

          span {
            font-weight: 400;
            font-size: 18px;
            line-height: 36px;
            text-align: justify;
          }
        }
      }

      .targetMarket__block__Right {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        gap: 20px;

        .targetMarket__block__RightTitle {
          width: 100%;
          display: flex;

          span {
            width: 100%;
            font-weight: 700;
            font-size: 30px;
            line-height: 68px;
            text-align: right;
          }
        }

        .targetMarket__block__RightImage {
          width: 100%;
          height: 450px;
          background-color: black;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            opacity: 65%;
          }
        }
      }
    }

    .targetMarket__block:nth-child(2n) {
      grid-template-columns: 0.6fr 1fr;

      .targetMarket__block__Left {
        order: 2;
      }

      .targetMarket__block__Right {
        order: 1;

        .targetMarket__block__RightTitle {
          span {
            text-align: left;
          }
        }
      }
    }
  }
}
