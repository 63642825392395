@import "variables";

.app {
  width: 100%;
}

.app_container {
  background-color: $background-color;
  min-height: 100vh;
  font-family: $font-family;
  /* Hide scrollbar for Chrome, Safari and Opera */
}
.app_container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.app_container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.topMask {
  height: $top-mask;
  width: 100%;
  background-color: black;

  @media (max-width: $mobile) {
    height: 55px;
  }
}
