$primary-color: #ffbf0f;
$background-color: #ffffff;
$font-color: #262626;
$font-color-light: #ffffff;
$font-color-dark: #ccd6dc;
$nav-color-black: #000000;

// mobile
$mobile: 1170px;
$mobile-small: 768px;

// font-family
$font-family: "Roboto", sans-serif;

//top-mask
// $top-mask: 130px;
$top-mask: 120px;
