@import "../../styles/foundations/mixins.scss";

.collapsible {
  display: flex;
  flex-direction: column;

  padding-bottom: 10px;
  box-sizing: border-box;

  .collapsible__container {
    display: flex;
    flex-direction: column;
  }

  .gridContainer {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(550px, 1fr));
    gap: 30px;

    padding: 30px 10px;
    box-sizing: border-box;
    overflow: hidden;

    @include desktop {
      gap: 20px;

      grid-template-columns: repeat(auto-fit, minmax(450px, 1fr));
    }

    @include smallDesktop {
      gap: 15px;
      grid-template-columns: 1fr;
      transition: all 0.3s ease-in-out;
    }

    .gridItem {
      display: flex;
      width: 100%;
      // height: 260px;
      // gap: 50px;
      gap: 40px;

      // box-shadow: rgba(255, 217, 111, 1) 0px 1px 3px 0px,
      //   rgba(255, 217, 111, 1) 0px 0px 0px 2px;

      box-shadow: #6b6b6b 0px 1px 3px 0px, #6b6b6b 0px 0px 0px 2px;
      transition: all 0.5s ease-in-out;
      cursor: pointer;

      &:hover {
        box-shadow: #6b6b6b 0px 1px 5px, #6b6b6b 0px 0px 0px 5px;
        transition: all 0.5s ease-in-out;
      }

      @include portraitTablet {
        gap: 10px;
        flex-direction: column;
        height: auto;
        padding: 0 20px;
        transition: all 0.3s ease-in-out;
      }

      .gridImg {
        width: 255px;
        height: 260px;
        display: flex;
        align-items: center;
        justify-content: center;

        // background-color: red;

        // @include miniPortraitTablet {
        //   width: 100%;
        //   height: 200px;
        //   transition: all 0.3s ease-in-out;
        // }

        img {
          // width: 98%;
          // height: 98%;

          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .gridContent {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        padding: 20px 20px 20px 0;
        box-sizing: border-box;

        @include miniPortraitTablet {
          gap: 10px;
          padding: 20px 0;
          transition: all 0.3s ease-in-out;
        }

        .catName {
          font-weight: 700;
          // font-size: 24px;
          font-size: 20px;

          // @include smallDesktop {
          //   font-size: 20px;
          //   transition: all 0.3s ease-in-out;
          // }

          @include miniPortraitTablet {
            font-size: 18px;
            transition: all 0.3s ease-in-out;
          }
        }

        p {
          font-weight: 400;
          font-size: 16px;
        }

        .spanTxt {
          font-weight: 700;
          font-size: 16px;

          @include smallDesktop {
            font-size: 14px;
            transition: all 0.3s ease-in-out;
          }
        }

        .gridBtnHolder {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          padding-right: 10px;

          // span {
          //   font-weight: 400;
          //   font-size: 16px;
          // }

          // .discover-btn-icon {
          //   background-image: url("../../assets/images/icons/View\ Details.png");
          //   background-size: contain;
          //   background-repeat: no-repeat;
          //   width: 20px;
          //   height: 10px;

          //   &:hover {
          //     background-image: url("../../assets/images/icons/Latest\ News\ More.png");
          //   }
          // }
        }
      }
    }
  }
}

//The link which when clicked opens the collapsable area
.Collapsible__trigger {
  display: block;
  font-weight: 400;
  text-decoration: none;
  position: relative;
  border-bottom: 1px solid #dcdcdc;
  padding: 10px;
  background: #ffffff;
  color: #252533;
  cursor: pointer;

  @include miniPortraitTablet {
    padding: 15px 10px;
    transition: all 0.3s ease-in-out;
  }

  @include largeMobile {
    padding: 10px 10px;
    transition: all 0.3s ease-in-out;
  }

  h1 {
    font-family: "oswald", sans-serif;
    font-size: 22px;
    line-height: 22px;
    font-weight: 400;

    @include smallDesktop {
      font-size: 22px;
      transition: all 0.3s ease-in-out;
    }

    @include miniPortraitTablet {
      font-size: 20px;
      transition: all 0.3s ease-in-out;
    }

    @include largeMobile {
      font-size: 18px;
      transition: all 0.3s ease-in-out;
    }
  }

  i {
    color: #dcdcdc;
  }
}
