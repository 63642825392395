@import "../../../styles/foundations/mixins.scss";

.partnerDetailPage {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  // padding: 150px 0 120px 0;
  // box-sizing: border-box;

  .partnerDetailPage__container {
    width: 100%;
    display: flex;
    flex-direction: column;

    gap: 42px;
    padding: 64px 0;
    box-sizing: border-box;

    @include tablet {
      padding: 30px 0 40px 0;
      transition: all 0.5s ease-in-out;
    }

    @include largeMobile {
      padding: 20px 0 40px 0;
    }

    .partnerLogo__container {
      width: 367px;
      height: 234px;
      display: flex;
      align-items: flex-start;
      padding: 5px;
      box-sizing: border-box;

      @include tablet {
        height: 200px;
      }

      @include largeMobile {
        width: 100%;
        height: 150px;
      }

      img {
        width: 100%;
        height: 100%;
        opacity: 95%;
        object-fit: contain;
      }
    }

    .partnerDetailPage__headerDesc__container {
      display: flex;
      flex-direction: column;

      .partnerDetailPage__header {
        display: flex;
        flex-direction: column;

        gap: 20px;

        .partnerDetailPage__header__text {
          font-size: 24px;
          font-weight: 700;
          line-height: 29px;
          text-align: justify;
        }

        .partnerDetailPage__desc__Text {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          text-align: justify;
        }
      }
    }

    // .partnerDetailPage__businessCatDesc__container {
    //   display: flex;
    //   flex-direction: column;

    //   gap: 42px;

    //   .partnerDetailPage__businessCatDesc__header {
    //     display: flex;

    //     .partnerDetailPage__businessCatDesc__header__text {
    //       font-weight: 700;
    //       font-size: 32px;
    //       line-height: 39px;
    //       text-align: justify;
    //     }
    //   }

    //   .partnerDetailPage__businessCatDesc__cardContainer {
    //     display: grid;
    //     grid-template-columns: repeat(2, 1fr);
    //     gap: 30px;

    //     .partnerDetailPage__businessCatDesc__card {
    //       position: relative;
    //       width: 570px;
    //       height: 250px;
    //       display: flex;
    //       flex-direction: column;

    //       .partnerDetailPage__businessCatDesc__card__image {
    //         height: 100%;
    //         width: 100%;

    //         img {
    //           height: 100%;
    //           width: 100%;
    //           object-fit: contain;
    //         }
    //       }

    //       .partnerDetailPage__businessCatDesc__card__text {
    //         position: absolute;
    //         bottom: 20px;
    //         left: 20px;

    //         span {
    //           font-weight: 700;
    //           font-size: 16px;
    //           line-height: 19px;
    //           align-items: center;
    //           text-align: justify;
    //           color: white;
    //         }
    //       }
    //     }
    //   }

    //   .partnerDetailPage__businessCatDesc__desc {
    //     display: flex;

    //     .partnerDetailPage__businessCatDesc__desc__text {
    //       font-weight: 400;
    //       font-size: 16px;
    //       line-height: 24px;
    //       text-align: justify;
    //     }
    //   }
    // }

    .partnerDetailPage__businessCart__container {
      width: 100%;
      display: flex;
      flex-direction: column;

      gap: 42px;

      @include tablet {
        gap: 30px;
      }

      @include largeMobile {
        gap: 20px;
      }

      .partnerDetailPage__businessCart__header {
        display: flex;

        .partnerDetailPage__businessCart__header__text {
          font-weight: 700;
          font-size: 32px;
          line-height: 39px;
          text-align: justify;
          color: var(--text__black);

          @include tablet {
            font-size: 24px;
            line-height: 30px;
          }

          @include largeMobile {
            font-size: 20px;
            line-height: 24px;
          }
        }
      }

      .partnerDetailPage__businessCart__cardContainer {
        display: grid;
        // grid-template-columns: repeat(4, 1fr);
        grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
        gap: 30px;

        @include mobile {
          grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
        }

        .partnerDetailPage__businessCart__card {
          position: relative;
          height: 270px;
          display: flex;
          flex-direction: column;

          padding: 5px;
          box-sizing: border-box;
          // border: 1px solid var(--border__black__shade2);
          border: 1px solid var(--yellow);
          border-radius: 5px;

          .partnerDetailPage__businessCart__card__image {
            height: 100%;
            width: 100%;

            img {
              height: 100%;
              width: 100%;
              object-fit: cover;
              border-radius: 5px;

              @include tablet {
                object-fit: cover;
              }
            }
          }

          .partnerDetailPage__businessCart__card__text {
            position: absolute;
            // height: 32px;
            bottom: 20px;
            left: 20px;

            display: flex;
            align-items: center;

            padding: 6px 10px;
            box-sizing: border-box;
            background-color: gainsboro;
            opacity: 65%;

            span {
              font-weight: 700;
              font-size: 16px;
              line-height: 19px;
              align-items: center;
              text-align: justify;
              color: black;

              @include tablet {
                font-size: 14px;
                line-height: 17px;
              }

              @include largeMobile {
                font-size: 12px;
                line-height: 15px;
              }
            }
          }
        }
      }

      .partnerDetailPage__businessCatDesc__desc {
        display: flex;

        .partnerDetailPage__businessCatDesc__desc__text {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          text-align: justify;
        }
      }
    }
  }
}
