@import "./../../variables";

@import "../../styles/foundations/mixins.scss";

.footer {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  background-color: var(--black);

  .footerContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .footer__topContainer {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 0.5fr;

      gap: 87px;
      padding: 80px 0 50px 0;
      box-sizing: border-box;
      transition: all 0.5s ease-in-out;

      @include desktop {
        grid-template-columns: 1fr;
        gap: 40px;
        transition: all 0.5s ease-in-out;
      }

      @include tablet {
        padding: 50px 0 30px 0;
        gap: 40px;
      }

      .footer__topContainer__left {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;

        gap: 87px;
        transition: all 0.5s ease-in-out;

        @include tablet {
          grid-template-columns: 1fr;
          gap: 40px;
          transition: all 0.5s ease-in-out;
        }

        .footer__topContainerLeft__companyDetailsContainer {
          //   background-color: brown;
          display: flex;
          flex-direction: column;
          gap: 20px;

          .footer__companyNameHeader {
            width: 100%;
            display: flex;

            padding-bottom: 5px;
            border-bottom: 1px solid var(--border__whiteGray);

            span {
              font-weight: 700;
              font-size: 40px;
              line-height: 42px;
              color: var(--white);

              @include tablet {
                font-size: 32px;
                line-height: 34px;
              }
            }
          }

          .footer__companyDetailsTextAndIconContainer {
            height: 100%;
            display: flex;

            gap: 20px;
            padding-bottom: 20px;
            border-bottom: 1px solid var(--border__whiteGray);

            .footer__companyDetailsIconr {
              i {
                margin-top: 7px;
                font-size: 16px;
                color: var(--white);
              }
            }

            .footer__companyDetailsTextWithHeader {
              display: flex;
              flex-direction: column;

              h1 {
                font-weight: 700;
                font-size: 24px;
                line-height: 29px;
                color: var(--text__white);
                transition: all 0.5s ease-in-out;

                @include tablet {
                  font-size: 20px;
                  line-height: 24px;
                  transition: all 0.5s ease-in-out;
                }
              }

              .footer__smtext {
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: var(--text__white__shade1);
                transition: all 0.5s ease-in-out;

                @include tablet {
                  font-size: 14px;
                  line-height: 20px;
                  transition: all 0.5s ease-in-out;
                }
              }
            }

            .footer__companyDetailsText {
              display: flex;
              gap: 5px;
              color: var(--text__white__shade1);
              transition: all 0.5s ease-in-out;

              @include tablet {
                flex-direction: column;
                transition: all 0.5s ease-in-out;
              }

              .footer__smtext {
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: var(--text__white__shade1);
                transition: all 0.5s ease-in-out;

                @include tablet {
                  font-size: 14px;
                  line-height: 20px;
                  transition: all 0.5s ease-in-out;
                }
              }
            }
          }

          .footer__socialIconContainer {
            display: flex;
            gap: 20px;

            .footer__socialIcon {
              i {
                font-size: 20px;
                color: var(--white);
                transition: all 0.5s ease-in-out;

                @include tablet {
                  font-size: 16px;
                  transition: all 0.5s ease-in-out;
                }
              }
            }
          }
        }

        .footer__topContainerLeft__linkContainer {
          //   background-color: blueviolet;
          width: 80%;
          display: flex;
          flex-direction: column;

          gap: 20px;

          .footer__linkHeader {
            display: flex;

            padding-bottom: 5px;
            border-bottom: 1px solid var(--border__whiteGray);

            h1 {
              font-weight: 500;
              font-size: 28px;
              line-height: 39px;
              color: var(--text__white);
              transition: all 0.5s ease-in-out;

              @include tablet {
                font-size: 24px;
                line-height: 34px;
                transition: all 0.5s ease-in-out;
              }
            }
          }

          .footer__linkContainer {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 20px;

            .footer__linkContainer__left {
              display: flex;
              flex-direction: column;
              gap: 12px;

              .footer__link {
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                color: var(--text__white__shade1);
              }
            }

            .footer__linkContainer__right {
              display: flex;
              flex-direction: column;
              gap: 12px;

              .footer__link {
                font-weight: 400;
                font-size: 16px;
                line-height: 19px;
                color: var(--text__white__shade1);
                transition: all 0.5s ease-in-out;

                @include tablet {
                  font-size: 14px;
                  line-height: 17px;
                  transition: all 0.5s ease-in-out;
                }
              }
            }
          }
        }
      }

      .footer__topContainer__right {
        width: 100%;
        display: flex;
        align-items: flex-end;

        // background-color: rgb(16, 46, 7);

        .footer__topContainer__right__img {
          width: 100%;

          img {
            width: 100%;
            height: 100%;
            transition: all 0.5s ease-in-out;

            @include wrapper2 {
              height: 100%;
              width: 500px;
              transition: all 0.5s ease-in-out;
            }
          }
        }
      }
    }
  }

  .footer__bottomContainer {
    display: flex;
    flex-direction: column;
    gap: 2px;

    span {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: var(--text__white__shade1);
    }

    img {
      width: 100%;
      height: 34px;
      transition: all 0.5s ease-in-out;

      @include tablet {
        height: 20px;
        transition: all 0.5s ease-in-out;
      }
    }
  }
}
