@import "../../../styles/foundations/mixins.scss";

.landingExploreProduct {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  gap: 30px;
  padding-top: 110px;

  @include wrapper1 {
    height: 100%;
    padding: 30px 0;
  }

  @include miniPortraitTablet {
    gap: 40px;
  }

  .landingExploreProductTitleAndIcon {
    width: max-content;
    display: flex;
    flex-direction: column;

    .landingExploreProduct__title {
      font-size: 56px;
      font-style: normal;
      font-weight: 700;

      @include wrapper1 {
        font-size: 36px;
      }

      @include tablet {
        font-size: 26px;
      }

      @include largeMobile {
        font-size: 20px;
      }
    }

    img {
      width: 100%;
      height: 5px;
    }
  }

  .landingPageProductFilter__container {
    width: 100%;
    display: flex;
    gap: 5px;

    align-items: flex-start;
    justify-content: flex-start;

    padding: 40px 0;
    box-sizing: border-box;

    @include wrapper1 {
      padding: 20px 0 0 0;
    }

    i {
      font-size: 25px;
      color: #febe13;
      cursor: pointer;
    }

    .iconDisabled {
      color: #797979;
    }

    .landingPageProductFilter__filterItemContainer {
      width: 100%;
      display: flex;
      background-color: transparent;
      overflow: hidden;

      .landingPageProductFilter__filterItem {
        margin-top: 10px;
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 20px;
        cursor: pointer;
        user-select: none;
        transition: all 0.5s ease-in-out;

        @include wrapper1 {
          gap: 10px;
        }

        .landingPageProductFilter__filterItem__line {
          height: 4px;
          width: 100%;
          background-color: #797979;
          //   cursor: pointer;
        }

        .landingPageProductFilter__filterItem__text {
          width: 100%;
          padding: 0 20px;
          box-sizing: border-box;

          button {
            width: max-content;
            font-weight: 400;
            font-size: 24px;
            line-height: 29px;

            @include wrapper1 {
              font-size: 20px;
            }

            @include largeMobile {
              font-size: 18px;
            }
          }
        }
      }

      .activeItem {
        color: #febe13;
        transition: all 0.5s ease-in-out;

        .landingPageProductFilter__filterItem__line {
          background-color: #febe13;
          transition: all 0.5s ease-in-out;
        }
      }
    }

    // .landingPageProductFilter__filterItemContainer .swiper-slide {
    //     background: #f2f2f2 !important;
    // }
  }

  .landingExploreProduct__container {
    width: 100%;
    height: 350px;

    .landingExploreProduct__containerItem {
      width: 100%;
      height: 350px;
      display: grid;
      grid-template-columns: 1fr 1fr;

      border: 1px solid var(--yellow);
      user-select: none;
      cursor: pointer;

      //   @include wrapper1 {
      //     height: 100%;
      //   }

      .lep__itemImg {
        height: 350px;
        width: 100%;

        padding: 10px;
        box-sizing: border-box;
        transition: all 0.3s ease-in-out;

        img {
          height: 100%;
          width: 100%;
          object-fit: fill;
        }
      }

      .lep__itemContent {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        gap: 15px;
        padding: 20px 30px;
        box-sizing: border-box;

        // background-color: #2b2b2b;
        // background-color: var(--yellow);
        background-color: #d9d9d9;

        @include largeMobile {
          padding: 20px 20px;
        }

        .lep__itemContentTop {
          display: flex;
          flex-direction: column;
          gap: 10px;

          .lep__itemContentTitle {
            font-size: 22px;
            font-weight: 700;
            // color: white;

            @include wrapper1 {
              font-size: 20px;
            }

            @include largeMobile {
              font-size: 18px;
            }
          }

          .lep__itemContentDesc {
            font-size: 16px;
            font-weight: 400;
            text-align: justify;
            color: white !important;

            @include largeMobile {
              font-size: 14px;
            }
          }
        }

        .lep__itemContentBtn {
          width: 100%;
          display: flex;
          align-items: flex-end;
          justify-content: flex-end;

          .link {
            cursor: pointer;

            img {
              height: 20px;
              width: 40px;

              @include largeMobile {
                height: 15px;
                width: 30px;
              }
            }
          }
        }
      }
    }
  }

  .landingExploreProduct__bottom {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .landingExploreProduct__bottomLeft {
      display: flex;
      gap: 20px;

      img:nth-child(1) {
        transform: rotate(180deg);
      }

      .landingExploreProductBtn {
        height: 40px;
        width: 40px;
        cursor: pointer;

        @include extraLargeTablet {
          height: 30px;
          width: 30px;
        }

        @include largeMobile {
          height: 25px;
          width: 25px;
        }
      }

      &.deactiveBtn {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }

    .landingExploreProduct__bottomRight {
    }
  }
}

.swiper {
  align-items: start;
  justify-content: start;
}
