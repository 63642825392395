@import "../../../styles/foundations/mixins.scss";

.openPositionDetails {
  display: flex;
  flex-direction: column;

  .openPositionDetails__container {
    margin-top: 50px;
    height: 100%;
    width: 100%;

    display: flex;
    flex-direction: column;

    // padding: 84px 0;
    // box-sizing: border-box;

    background-image: url(".././../../assets/images//work-with-us/openPositionDetails/bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    background-color: #d9d9d9;

    @include tablet {
      margin-top: 30px;
    }

    .openPositionDetails__content {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;

      gap: 50px;
      padding: 84px 0;
      box-sizing: border-box;

      @include tablet {
        gap: 30px;
        padding: 30px 0;
      }

      .penPositionDetails__headerAndBtn {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        padding: 0 0 10px 0;
        border-bottom: 1px solid #818181;

        @include tablet {
          gap: 20px;
        }

        @include portraitTablet {
          flex-direction: column;
          align-items: flex-start;
          gap: 10px;
        }

        .openPositionDetails__header {
          span {
            font-weight: 700;
            font-size: 48px;
            line-height: 48px;

            @include tablet {
              font-size: 32px;
              line-height: 32px;
            }

            @include largeMobile {
              font-size: 24px;
              line-height: 24px;
            }
          }
        }

        .openPositionDetails__btn {
          .linkBtn {
            width: 180px;
            height: 60px;
            display: flex;
            align-content: center;
            justify-content: center;

            @include tablet {
              width: 150px;
              height: 50px;
            }

            @include largeMobile {
              width: 120px;
              height: 40px;
            }

            button {
              width: 100%;
              height: 100%;
              background-color: #2b2b2b;
              color: #ffffff;
              border: none;
              outline: none;
              cursor: pointer;
              font-weight: 700;
              font-size: 22px;
              line-height: 18px;

              @include tablet {
                font-size: 18px;
                line-height: 14px;
              }

              @include largeMobile {
                font-size: 16px;
                line-height: 12px;
              }
            }
          }
        }
      }

      .openPositionDetails__positionInfoContainer {
        display: flex;
        flex-direction: column;

        .positionInfo {
          display: flex;
          gap: 20px;

          @include tablet {
            gap: 10px;
          }

          @include largeMobile {
            gap: 5px;
          }

          .positionInfo__header {
            span {
              font-weight: 700;
              font-size: 24px;
              line-height: 48px;

              @include tablet {
                font-size: 18px;
                line-height: 36px;
              }

              @include largeMobile {
                font-size: 16px;
                line-height: 32px;
              }
            }
          }

          .positionInfo__content {
            span {
              font-weight: 400;
              font-size: 24px;
              line-height: 48px;

              @include tablet {
                font-size: 18px;
                line-height: 36px;
              }

              @include largeMobile {
                font-size: 16px;
                line-height: 32px;
              }
            }
          }
        }
      }

      .openPosition__commonHeaderAndContent {
        display: flex;
        flex-direction: column;

        gap: 20px;

        @include tablet {
          gap: 10px;
        }

        @include largeMobile {
          gap: 5px;
        }

        .openPosition__commonHeader {
          span {
            font-weight: 700;
            font-size: 32px;
            line-height: 39px;

            @include tablet {
              font-size: 24px;
              line-height: 29px;
            }

            @include largeMobile {
              font-size: 18px;
              line-height: 22px;
            }
          }
        }

        .openPosition__commonContent {
          width: 100%;
          display: flex;

          span {
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            text-align: justify;
          }
        }
      }

      .prefferContactAndBtnContainer {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .prefferContact {
          width: 100%;
          display: flex;
          padding-bottom: 40px;
          border-bottom: 1px solid #818181;

          span {
            font-weight: 700;
            font-size: 24px;
            line-height: 36px;
          }
        }

        .openPositionBottom__BtnContainer {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          @include tablet {
            flex-direction: column;
            align-items: start;
            gap: 20px;
          }

          .openPositionBottom__BtnContainer__left {
            display: flex;
            align-items: center;
            gap: 20px;

            @include tablet {
              flex-direction: column;
              align-items: start;
              gap: 20px;
            }

            .positionDetails__applyBtn {
              width: 180px;
              height: 60px;
              background-color: #2b2b2b;
              color: #ffffff;
              border: none;
              outline: none;
              cursor: pointer;
              font-weight: 700;
              font-size: 22px;
              line-height: 18px;

              @include tablet {
                width: 150px;
                height: 50px;
                font-size: 18px;
                line-height: 14px;
              }

              @include largeMobile {
                width: 120px;
                height: 40px;
                font-size: 16px;
                line-height: 12px;
              }
            }

            .positionDetails__prevApply {
              width: 294px;
              height: 60px;
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 10px;
              border: 1px solid #2b2b2b;
              transition: all 0.3s ease-in-out;

              @include tablet {
                width: 250px;
                height: 50px;
              }

              @include largeMobile {
                width: 100%;
                height: 100%;
                padding: 8px 20px;
              }

              img {
                width: 40px;
                height: 16px;

                @include largeMobile {
                  width: 30px;
                  height: 12px;
                }
              }

              span {
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
              }

              &:hover {
                border: 1px solid orange;
                transition: all 0.3s ease-in-out;
              }
            }
          }

          .openPositionBottom__BtnContainer__right {
            display: flex;
            align-items: center;
            gap: 20px;

            @include largeMobile {
              gap: 10px;
            }

            span {
              font-weight: 700;
              font-size: 32px;
              line-height: 32px;

              @include tablet {
                font-size: 24px;
                line-height: 24px;
              }

              @include largeMobile {
                font-size: 18px;
                line-height: 18px;
              }
            }

            i {
              font-size: 22px;
              line-height: 32px;
              cursor: pointer;

              @include tablet {
                font-size: 18px;
                line-height: 24px;
              }

              @include largeMobile {
                font-size: 16px;
                line-height: 18px;
              }
            }
          }
        }
      }
    }
  }
}
