@import "../../styles/foundations/mixins.scss";

.downloadSwiper {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  gap: 20px;

  .downloadSwiper__header {
    display: flex;
    flex-direction: column;

    .downloadSwiper__headerText {
      font-family: "oswald";
      font-size: 30px;
      font-weight: 800;
      color: #000000;

      @include extraLargeTablet {
        font-size: 25px;
      }
    }
  }

  .downloadSwiper__swiperContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .downloadSwiper__swiper {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;

      .downloadSwiper__swiperItem {
        // height: 360px;
        // width: 270px;

        // height: 360px;
        height: 250px;
        width: 100%;

        display: flex;
        cursor: pointer;

        @include portraitTablet {
          height: 300px;
          width: 220px;
        }

        .downloadSwiper__swiperItemCont {
          height: 100%;
          width: 100%;
          display: flex;

          img {
            height: 100%;
            width: 100%;
            object-fit: contain;
          }
        }
      }
    }
  }

  .download__swiperBtnContainer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    gap: 15px;
  }
}
