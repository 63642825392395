@import "../../../styles/foundations/mixins.scss";

.landingReferenceProject {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @include wrapper1 {
    height: 100%;
    padding: 30px 0;
  }

  .landingRefProject__container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    .landingReferenceProjectTitleAndIcon {
      width: max-content;
      display: flex;
      flex-direction: column;

      .landingReferenceProject__title {
        font-size: 56px;
        font-style: normal;
        font-weight: 700;

        @include wrapper1 {
          font-size: 36px;
        }

        @include tablet {
          font-size: 26px;
        }

        @include largeMobile {
          font-size: 20px;
        }
      }

      img {
        width: 100%;
        height: 5px;
      }
    }

    .landingRefProject__itemContainer {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 0.5fr 0.5fr;

      gap: 30px;
      padding-top: 60px;

      @include semiLargeDesktop {
        grid-template-columns: 1fr 1fr 1fr;
        gap: 20px;
        padding-top: 30px;
      }

      @include mediumTablet {
        grid-template-columns: 1fr;
      }

      .landingRefProject__item {
        position: relative;
        width: 100%;
        height: 240px;
        display: flex;

        .landingRefProject__itemImage {
          width: 100%;
          height: 100%;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .landingRefProject__itemText {
          position: absolute;
          bottom: 20px;
          left: 20px;
          width: 230px;
          display: flex;
          flex-direction: column;
          padding: 10px;
          z-index: 1;
          background-color: rgb(212, 213, 214);
          opacity: 0.8;

          @include largeMobile {
            width: 90%;
          }

          .landingRefProject__itemTitle {
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            color: black;

            @include mediumTablet {
              font-size: 16px;
            }
          }

          .landingRefProject__itemLocation {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            color: black;

            @include mediumTablet {
              font-size: 12px;
            }
          }
        }
      }
    }

    .landingRefProject__itemContainer2 {
      width: 100%;
      display: grid;
      grid-template-columns: 0.45fr 0.45fr 1fr;

      gap: 30px;
      padding-top: 30px;

      @include semiLargeDesktop {
        grid-template-columns: 1fr 1fr 1fr;
        gap: 20px;
      }

      @include mediumTablet {
        grid-template-columns: 1fr;
      }

      .landingRefProject__item {
        position: relative;
        width: 100%;
        height: 240px;
        display: flex;

        .landingRefProject__itemImage {
          width: 100%;
          height: 100%;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .landingRefProject__itemText {
          position: absolute;
          bottom: 20px;
          left: 20px;
          width: 230px;
          display: flex;
          flex-direction: column;
          padding: 10px;
          z-index: 1;
          background-color: rgb(212, 213, 214);
          opacity: 0.8;

          @include largeMobile {
            width: 90%;
          }

          .landingRefProject__itemTitle {
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            // color: black;
          }

          .landingRefProject__itemLocation {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            // color: black;
          }
        }
      }
    }

    .landingRefProject__btnContainer {
      padding-top: 30px;
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }
}
