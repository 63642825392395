@import "../../../../styles/foundations/mixins.scss";

.textIconBtnWhite {
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    transition: all 0.3s ease-in-out;

    .textIconBtnTextWhite {
      color: orange;
      transition: all 0.3s ease-in-out;
    }

    svg {
      #Vector_1 {
        fill: orange;
        transition: all 0.1s ease-in-out;
      }
      #Vector_2 {
        fill: orange;
        transition: all 0.2s ease-in-out;
      }
      #Vector_4 {
        fill: orange;
        transition: all 0.4s ease-in-out;
      }
      #Vector_3 {
        fill: orange;
        transition: all 0.5s ease-in-out;
      }
      #Vector_5 {
        fill: orange;
        transition: all 0.7s ease-in-out;
      }
    }
  }

  .textIconBtnTextWhite {
    font-weight: 700;
    font-size: 24px;
    color: white;
    text-decoration: none;

    @include extraLargeTablet {
      font-size: 20px;
    }

    @include largeMobile {
      font-size: 16px;
    }
  }

  svg {
    width: 56px;
    height: 22px;
    margin-top: 5px;

    @include extraLargeTablet {
      width: 40px;
      height: 20px;
    }

    @include largeMobile {
      width: 30px;
      height: 18px;
    }
  }
}

.textIconBtnBlack {
  width: max-content;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  &:hover {
    transition: all 0.3s ease-in-out;

    .textIconBtnTextBlack {
      color: orange;
      transition: all 0.3s ease-in-out;
    }

    svg {
      #Vector_1 {
        fill: orange;
        transition: all 0.1s ease-in-out;
      }
      #Vector_2 {
        fill: orange;
        transition: all 0.2s ease-in-out;
      }
      #Vector_4 {
        fill: orange;
        transition: all 0.4s ease-in-out;
      }
      #Vector_3 {
        fill: orange;
        transition: all 0.5s ease-in-out;
      }
      #Vector_5 {
        fill: orange;
        transition: all 0.7s ease-in-out;
      }
    }
  }

  .textIconBtnTextBlack {
    font-weight: 700;
    font-size: 24px;
    color: black;
    text-decoration: none;

    @include extraLargeTablet {
      font-size: 20px;
    }

    @include largeMobile {
      font-size: 16px;
    }
  }

  svg {
    width: 56px;
    height: 22px;
    margin-top: 5px;

    @include extraLargeTablet {
      width: 40px;
      height: 20px;
    }

    @include largeMobile {
      width: 30px;
      height: 18px;
    }
  }
}
