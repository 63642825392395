@import "../../../styles/foundations/mixins.scss";

.openPositions {
  display: flex;
  flex-direction: column;

  .openPositions__container {
    display: flex;
    flex-direction: column;

    padding: 64px 0;
    box-sizing: border-box;
    transition: all 0.5s ease-in-out;

    @include tablet {
      padding: 30px 0 40px 0;
      transition: all 0.5s ease-in-out;
    }

    @include largeMobile {
      padding: 20px 0 40px 0;
    }

    .openPositions__headerDesc {
      display: flex;
      flex-direction: column;

      .openPositions__headerText {
        width: max-content;
        font-weight: 700;
        font-size: 48px;
        line-height: 58px;

        @include tablet {
          font-size: 38px;
          line-height: 48px;
        }

        @include largeMobile {
          font-size: 28px;
          line-height: 38px;
        }
      }

      .openPositions__headerText__underline {
        width: 85px;
        height: 1px;
        background-color: black;
      }

      .openPositions__descText {
        margin-top: 42px;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: justify;

        @include tablet {
          margin-top: 32px;
          font-size: 14px;
          line-height: 22px;
        }

        @include largeMobile {
          margin-top: 22px;
        }
      }
    }

    .positions__container {
      width: 100%;
      margin-top: 72px;

      @include tablet {
        margin-top: 32px;
      }

      @include largeMobile {
        margin-top: 22px;
      }

      .positions__header {
        display: flex;
        flex-direction: column;

        .positions__headerText {
          font-weight: 700;
          font-size: 32px;
          line-height: 42px;

          @include tablet {
            font-size: 28px;
            line-height: 38px;
          }

          @include largeMobile {
            font-size: 20px;
            line-height: 30px;
          }
        }

        .positions__headerText__underline {
          width: 65px;
          height: 1px;
          background-color: black;
        }
      }

      .positions__rowContainer {
        width: 100%;
        display: flex;
        flex-direction: column;

        gap: 10px;
        padding: 10px 0 0 10px;
        box-sizing: border-box;

        .positions__row {
          width: 100%;
          display: flex;
          justify-content: space-between;

          padding: 6px 0;
          box-sizing: border-box;
          border-bottom: 1px solid #c4c4c4;
          cursor: pointer;

          @include tablet {
            gap: 30px;
          }

          .positions__rowLeft {
            span {
              font-weight: 400;
              font-size: 16px;
              // line-height: 48px;

              @include tablet {
                font-size: 14px;
                // line-height: 38px;
              }
            }
          }

          .positions__rowRight {
            display: flex;
            align-items: center;
            gap: 10px;

            span {
              font-weight: 400;
              font-size: 16px;
              // line-height: 48px;

              @include tablet {
                font-size: 14px;
                // line-height: 38px;
              }
            }
          }
        }
      }
    }
  }
}
