@import "../../../styles/foundations/mixins.scss";

.homeAbout {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  gap: 40px;
  padding-top: 110px;

  @include wrapper1 {
    height: 100%;
    padding-top: 0;
  }

  @include miniPortraitTablet {
    gap: 20px;
  }

  .homeAbout__container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 30px;

    @include wrapper1 {
      padding: 30px 0;
    }

    .homeAboutTitleAndIcon {
      width: max-content;
      display: flex;
      flex-direction: column;

      .homeAbout__title {
        font-size: 56px;
        font-weight: 700;

        @include wrapper1 {
          font-size: 36px;
        }

        @include tablet {
          font-size: 26px;
        }

        @include miniPortraitTablet {
          font-size: 20px;
        }
      }

      img {
        width: 100%;
        height: 5px;
      }
    }

    .homeAbout__swiper {
      width: 100%;

      .homeAbout__swiperSlide {
        height: 100%;
        width: 100%;
        display: flex;

        .homeAbout__swiperSlideContainer {
          height: 100%;
          width: 100%;
          display: flex;
          flex-direction: column;
          row-gap: 20px;

          padding: 10px;
          box-sizing: border-box;
          border: 1px solid rgb(223, 219, 219);
          border-radius: 5px;

          &:hover {
            border: 1px solid orange;
            transition: all 0.5s ease-in-out;
          }

          @include largeMobile {
            row-gap: 10px;
          }

          .homeAbout__slideImage {
            height: 340px;
            width: 100%;
            display: flex;
            background-color: black;

            @include largeMobile {
              height: 250px;
            }

            img {
              height: 100%;
              width: 100%;
              object-fit: fill;
              opacity: 65%;
            }
          }

          .homeAbout__slideContent {
            height: 130px;
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 20px;
            overflow: hidden;

            @include largeMobile {
              height: 150px;
              row-gap: 10px;
            }

            .homeAbout__slideTitle {
              font-size: 24px;
              font-style: normal;
              font-weight: 700;

              @include tablet {
                font-size: 20px;
              }
            }

            .homeAbout__slideDesc {
              font-size: 15px;
              text-align: justify;
              font-weight: 400;
            }
          }
        }
      }
    }

    .homeAbout__scrollbar1 {
      height: 10px;
      width: 100%;
      overflow: hidden;

      .slider {
        width: 100%;
        background-color: #ddd;
        overflow: visible;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .thumb {
        height: 10px;
        width: 150px;
        background-color: #ffbf0f;
        outline: none;
        -webkit-box-shadow: 0px 0px 5px 0px rgba(255, 201, 24, 0.75);
        -moz-box-shadow: 0px 0px 5px 0px rgba(255, 255, 255, 0.75);
        box-shadow: 0px 0px 5px 0px rgba(255, 255, 255, 0.75);
        cursor: pointer;
      }

      .track {
        width: 100%;
        height: 10px;
        background-color: #d6d6d6;
        overflow: visible;
      }
    }

    .homeAbout__btn {
      width: 100%;
      display: flex;
      justify-content: flex-end;
    }
  }
}
